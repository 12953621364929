<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <form class="needs-validation" name="form" #f="ngForm">
      <div #header class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModalLabel">{{'Modal_Title' | translate }}# {{leavesData.id}}</h5>
        <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
          <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
          <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
        </button>
        <button type="button" class="close" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [style.pointer-events]="isView?'none':''">
        <div class="form row">
          <div class="form-group col-sm-6">
            <label for="" class="required">{{'Modal_Inputs.FromDate_Label' | translate }}</label>
            <mat-form-field>
              <input matInput [matDatepicker]="picker159" placeholder="{{'Modal_Inputs.FromDate_Placeholder' | translate }}" name="fromdate"
                [(ngModel)]="leavesData.fromdate" (dateChange)="updateCalcs();getLeavesType()" #fromdate="ngModel"
                [ngClass]="{ 'is-invalid': fromdate.invalid && fromdate?.errors , 'is-valid': fromdate.valid}" required
                [matDatepickerFilter]="publicHolidayFilter">
              <mat-datepicker-toggle matSuffix [for]="picker159">
              </mat-datepicker-toggle>
              <mat-datepicker #picker159></mat-datepicker>
            </mat-form-field>
            <div *ngIf="fromdate.invalid && fromdate.errors" class="invalid-feedback d-block">
              <div *ngIf="fromdate.errors.required">{{'Modal_Inputs.FromDate_required' | translate }}
              </div>
            </div>
          </div>
          <div class="form-group col-sm-6">
            <label for="" class="required">{{'Modal_Inputs.ToDate_Label' | translate }}</label>
            <mat-form-field>
              <input matInput [matDatepicker]="picker160" placeholder="{{'Modal_Inputs.ToDate_Placeholder' | translate }}" name="todate"
                [(ngModel)]="leavesData.todate" (dateChange)="updateCalcs()" #todate="ngModel"
                [ngClass]="{ 'is-invalid': todate.invalid && todate?.errors , 'is-valid': todate.valid}" required
                [matDatepickerFilter]="publicHolidayFilter">
              <mat-datepicker-toggle matSuffix [for]="picker160">
              </mat-datepicker-toggle>
              <mat-datepicker #picker160></mat-datepicker>
            </mat-form-field>
            <div *ngIf="todate.invalid && todate.errors" class="invalid-feedback d-block">
              <div *ngIf="todate.errors.required">{{'Modal_Inputs.ToDate_required' | translate }}
              </div>
            </div>
          </div>
          <div class="form-group col-sm-6">
            <label for="">{{'Modal_Inputs.HalfDayStart_Label' | translate }}</label>
            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <label class="d-block" for="">
                <input class="radio_animated" [value]=true id="halfday" type="radio" name="halfday"
                  [(ngModel)]="leavesData.halfday1" [class.disabled]="fieldsetDisabled" (change)="updateCalcs()">
                {{'Modal_Inputs.HalfDayStart_OptionYes' | translate }}
              </label>
              <label class="d-block" for="">
                <input class="radio_animated" [value]=false id="halfday1" (change)="updateCalcs()" type="radio"
                  name="halfday1" [(ngModel)]="leavesData.halfday1" [class.disabled]="fieldsetDisabled">
                {{'Modal_Inputs.HalfDayStart_OptionNo' | translate }}
              </label>
            </div>
          </div>
          <div class="form-group col-sm-6">
            <label for="">{{'Modal_Inputs.HalfDayResume_Label' | translate }}</label>
            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <label class="d-block" for="">
                <input class="radio_animated" [value]=true id="halfday2" type="radio" name="halfday2"
                  [(ngModel)]="leavesData.halfday2" [class.disabled]="fieldsetDisabled" (change)="updateCalcs()">
                {{'Modal_Inputs.HalfDay_OptionYes' | translate }}
              </label>
              <label class="d-block" for="">
                <input class="radio_animated" [value]=false id="halfday3" type="radio" name="halfday3"
                  [(ngModel)]="leavesData.halfday2" [class.disabled]="fieldsetDisabled" (change)="updateCalcs()">
                {{'Modal_Inputs.HalfDay_OptionNo' | translate }}
              </label>
            </div>
          </div>
          <div class="form-group col-sm-6">
            <label for="">{{'Modal_Inputs.TotalLeaves_Label' | translate }}</label>
            <input type="number" class="form-control" name="totalleaves" readonly [(ngModel)]="leavesData.tlp">
          </div>
          <div class="form-group col-sm-6">
            <label for="" class="required">{{'Modal_Inputs.Type_Label' | translate }}</label>
            <select class="form-control" required="" name="leavesconfigid" [(ngModel)]="leavesData.leavesconfigid">
              <option value="">{{'Modal_Inputs.Type_Placeholder' | translate }}</option>
              <option *ngFor="let cust of typeList" [value]="cust.Value">{{cust.Text}}
              </option>
            </select>
          </div>
          <div class="form-group col-sm-6">
            <label for="">{{'Modal_Inputs.PhoneDuringLeave_Label' | translate }}</label>
            <input type="text" class="form-control" name="phoneduringleave" [(ngModel)]="leavesData.phoneduringleave">
          </div>
          <div class="form-group col-sm-6">
            <label for="">{{'Modal_Inputs.Reason_Label' | translate }}</label>
            <textarea type="text" class="form-control" cols="30" rows="2" name="reason"
              [(ngModel)]="leavesData.reason"></textarea>
          </div>
          <div class="form-group col-sm-6">
            <label for="">{{'Modal_Inputs.AddressDuringLeave_Label' | translate }}</label>
            <textarea type="text" class="form-control" cols="30" rows="2" name="addressduringleave"
              [(ngModel)]="leavesData.addressduringleave"></textarea>
          </div>
          <div class="form-group col-sm-6">
            <label for="">{{'Modal_Inputs.BackupDuringLeave_Label' | translate }}</label>
            <textarea type="text" class="form-control" cols="30" rows="2" name="backup"
              [(ngModel)]="leavesData.backupduringleave"></textarea>
          </div>
          <div class="form-group col-sm-6">
            <label for="">{{'Modal_Inputs.ManagerNotes_Label' | translate }}</label>
            <textarea type="text" class="form-control" [readonly]="!isShowStatusDropdown" cols="30" rows="2"
              name="managernotes" [(ngModel)]="leavesData.managernotes"></textarea>
          </div>
          <div class="form-group col-sm-6">
            <label for="">{{'Modal_Inputs.ApprovedByManager_Label' | translate }}</label>
            <select class="form-control" required="" [disabled]="!isShowStatusDropdown" name="approvedbymanager"
              [(ngModel)]="leavesData.approvedbymanager">
              <option value="">{{'Modal_Inputs.ApprovedByManager_Placeholder' | translate }}</option>
              <option *ngFor="let item of booleanList" [value]="item.Value">{{item.Text}}
              </option>
            </select>
          </div>
          <div class="form-group col-sm-6">
            <label for="">{{'Modal_Inputs.HRNotes_Label' | translate }}</label>
            <textarea type="text" class="form-control" [readonly]="!isShowStatusDropdown" cols="30" rows="2"
              name="hrnotes" [(ngModel)]="leavesData.hrnotes"></textarea>
          </div>
          <div class="form-group col-sm-6">
            <label for="">{{'Modal_Inputs.Manager_Label' | translate }}</label>
            <textarea type="text" [readonly]="!isShowStatusDropdown" class="form-control" cols="30" rows="2"
              name="manager" [(ngModel)]="leavesData.manager"></textarea>
          </div>
          <div class="form-group col-sm-6">
            <label for="">{{'Modal_Inputs.ApprovedByHR_Label' | translate }}</label>
            <select class="form-control" required="" name="approvedbyhr" [disabled]="!isShowStatusDropdown"
              [(ngModel)]="leavesData.approvedbyhr">
              <option value="">{{'Modal_Inputs.ApprovedByHR_Placeholder' | translate }} </option>
              <option *ngFor="let item of booleanList" [value]="item.Value">{{item.Text}}
              </option>
            </select>
          </div>
          <div class="form-group col-sm-6">
            <label for="">{{'Modal_Inputs.Status_Label' | translate }}</label>
            <select class="form-control" required="" name="status" [disabled]="!isShowStatusDropdown"
              [(ngModel)]="leavesData.status">
              <option value="">{{'Modal_Inputs.Status_Placeholder' | translate }}</option>
              <option *ngFor="let cust of statusList" [value]="cust.stringValue">{{cust.Text}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="close()">{{'Modal_Buttons.Close_Button' | translate }}</button>
        <button type="button" class="btn btn-primary" [disabled]="!f.valid || isView"
          (click)="saveEmpLeaves()">{{'Modal_Buttons.Save_Button' | translate }}</button>
      </div>
    </form>
  </div>
</resize-border>