import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/shared/service/language.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { purchaseServices } from '../../purchase/purchase.service';
import { ProductviewComponent } from '../productview/productview.component';

@Component({
  selector: 'app-pocosting',
  templateUrl: './pocosting.component.html',
  styleUrls: ['./pocosting.component.scss']
})
export class PocostingComponent implements OnInit {
  fullScreen = false;
  @Input('params') params;
  poDetails: any = {
    pon: ''
  };
  private langChangeSubscription: Subscription; 
  costingItems: any = [];
  userdata: any;
  appname = environment.APP_NAME;
  poCostingDetails: any = {
    "id": null,
    "sid": null,
    "doc": null,
    "dor": null,
    "notes": null,
    "duty": 0,
    "invtotal": 0,
    "warehousefee": 0,
    "freight": 0,
    "haulfee": 0,
    "wfee": 0,
    "customsfee": 0,
    "docfee": 0,
    "cratefee": 0,
    "tax": 0,
    "insurance": 0,
    "handlingfee": 0,
    "servicefee": 0,
    "fdeliveryfee": 0,
    "otherfee": 0,
    "ldeliveryfee": 0,
    "hzmatfee": 0,
    "companyid": null,
    "totalcf": 0,
    "wfpercentage": 0,
    "approvedby": null,
    "approveddate": null,
    "iscosted": false,
    "deletedate": null,
    "createby": null,
    "createdate": null,
    "updateby": null,
    "updatedate": null,
    "demurragefee": 0
  };
  constructor(
    private translate: TranslateService, 
    private languageService: LanguageService, 
    private http: HttpClient, 
    private activeModal: NgbActiveModal,
    private toastr: NotificationService, private datepipe: DatePipe, private loader: NgxUiLoaderService, private service: purchaseServices, private modalService: NgbModal, private router: Router) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }
  

  ngOnInit(): void {
    this.poDetails = this.params;
    this.getPOCosting();
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
      this.loadComponentTranslations(lang); 
    });     
  }

  private loadComponentTranslations(lang: string) { 
    const translationFilePath = `./assets/i18n/purchase/purchaseorders/pocosting/${lang}.json`; 
    this.http.get(translationFilePath).subscribe((translations:any) => { 
    this.translate.setTranslation(lang, translations, true); 
    this.translate.use(lang); 
    }); 
     } 
  getPOItems() {
    this.loader.start();
    this.service.getPOItemConsting(this.poDetails.id).subscribe(res => {
      this.loader.stop();
      this.costingItems = res;
      this.costingItems.forEach(item => {
        item.podate = this.datepipe.transform(item.podate, this.userdata.date_format);
      })
    }, err => {
      this.loader.stop();
    });
  }
  getPOCosting() {
    this.loader.start();
    this.service.getPOCosting(this.poDetails.id).subscribe(res => {
      this.loader.stop();
      this.poCostingDetails = res;
      this.poCostingDetails.iscosted = this.poCostingDetails.iscosted ? (this.poCostingDetails.iscosted ? this.poCostingDetails.iscosted : false) : false;
      this.makeDecimals();
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading po data ', err);
    });
  }

  recalculate() {
    this.costingItems.forEach(item => {
      item.podate = this.datepipe.transform(item.podate, this.userdata.postdatetimeformat);
    })
    this.loader.start();
    var obj = {
      "items": JSON.stringify(this.costingItems),
      "id": this.poDetails.id,
      "updateby": this.userdata.email,
      "updatedate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "companyid": this.userdata.companyid
    }
    this.service.postRecalculateItem(obj).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.getPOItems();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading bol data ', err);
    });
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  onSortClick(event) {

  }
  tabClick(event) {
    if (event.nextId == '2') {
      this.getPOItems();
    }
  }
  ProdctView(value) {
    var modalRef = this.modalService.open(ProductviewComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    modalRef.componentInstance.item = value;
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
      this.getPOItems();
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  postPOCosting() {
    if (this.poCostingDetails.dor == undefined || this.poCostingDetails.dor == null) {
      this.toastr.Error('Please enter date of release');
      return;
    }
    if (this.poCostingDetails.doc == undefined || this.poCostingDetails.doc == null) {
      this.toastr.Error('Please enter date of calculation');
      return;
    }
    if (this.poCostingDetails.id == undefined || this.poCostingDetails.id == null || this.poCostingDetails.id == 0) {
      this.poCostingDetails.companyid = this.userdata.companyid;
      this.poCostingDetails.createby = this.userdata.email;
      this.poCostingDetails.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    } else {
      this.poCostingDetails.updateby = this.userdata.email;
      this.poCostingDetails.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    this.poCostingDetails.approveddate = this.datepipe.transform(this.poCostingDetails.approveddate, this.userdata.postdateformat);
    this.poCostingDetails.dor = this.datepipe.transform(this.poCostingDetails.dor, this.userdata.postdateformat);
    this.poCostingDetails.doc = this.datepipe.transform(this.poCostingDetails.doc, this.userdata.postdateformat);
    this.loader.start();
    this.service.postPOCosting(this.poCostingDetails).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.getPOCosting();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading po data ', err);
    });
  }
  approvePOCosting() {
    if (this.costingItems.length == 0) {
      this.toastr.Error('Please check PO if received & try again')
      return;
    }
    this.loader.start();
    this.service.postApprovePOCosting(this.poDetails.id).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.redirectAction();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading po data ', err);
    });
  }

  redirectAction() {
    Swal.fire({
      title: 'PO costing submitted, Do you want to review pricing?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.closeModal();
        this.router.navigate(['/pricings'], { replaceUrl: true });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.closeModal();
      }
    })
  }
  calculatePercent() {
    var perValue = 0;
    if (this.poCostingDetails.wfpercentage) {
      perValue = this.poCostingDetails.wfpercentage;
    } else {
      this.poCostingDetails.wfpercentage = 0;
    }
    this.poCostingDetails.wfee = (Number(this.poCostingDetails.invtotal) * Number(perValue)) / 100;
    this.poCostingDetails.wfee = this.poCostingDetails.wfee ? Number(this.poCostingDetails.wfee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.wfpercentage ? Number(this.poCostingDetails.wfpercentage).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.calculateTotal();
  }
  calculateTotal() {
    var costTotal = 0;
    // var invtotal = this.poCostingDetails.invtotal ? Number(this.poCostingDetails.invtotal) : 0;
    var duty = this.poCostingDetails.duty ? Number(this.poCostingDetails.duty) : 0;
    var warehousefee = this.poCostingDetails.warehousefee ? Number(this.poCostingDetails.warehousefee) : 0;
    var freight = this.poCostingDetails.freight ? Number(this.poCostingDetails.freight) : 0;
    var haulfee = this.poCostingDetails.haulfee ? Number(this.poCostingDetails.haulfee) : 0;
    var customsfee = this.poCostingDetails.customsfee ? Number(this.poCostingDetails.customsfee) : 0;
    var docfee = this.poCostingDetails.docfee ? Number(this.poCostingDetails.docfee) : 0;
    var cratefee = this.poCostingDetails.cratefee ? Number(this.poCostingDetails.cratefee) : 0;
    var tax = this.poCostingDetails.tax ? Number(this.poCostingDetails.tax) : 0;
    var insurance = this.poCostingDetails.insurance ? Number(this.poCostingDetails.insurance) : 0;
    var handlingfee = this.poCostingDetails.handlingfee ? Number(this.poCostingDetails.handlingfee) : 0;
    var servicefee = this.poCostingDetails.servicefee ? Number(this.poCostingDetails.servicefee) : 0;
    var fdeliveryfee = this.poCostingDetails.fdeliveryfee ? Number(this.poCostingDetails.fdeliveryfee) : 0;
    var otherfee = this.poCostingDetails.otherfee ? Number(this.poCostingDetails.otherfee) : 0;
    var ldeliveryfee = this.poCostingDetails.ldeliveryfee ? Number(this.poCostingDetails.ldeliveryfee) : 0;
    var hzmatfee = this.poCostingDetails.hzmatfee ? Number(this.poCostingDetails.hzmatfee) : 0;
    var wfpercentage = this.poCostingDetails.wfpercentage ? Number(this.poCostingDetails.wfpercentage) : 0;
    var demurragefee = this.poCostingDetails.demurragefee ? Number(this.poCostingDetails.demurragefee) : 0;
    var wfee = this.poCostingDetails.wfee ? Number(this.poCostingDetails.wfee) : 0;
    costTotal = duty + warehousefee + freight + haulfee + customsfee + docfee + cratefee + tax + insurance + handlingfee + servicefee + fdeliveryfee + otherfee + ldeliveryfee + hzmatfee + demurragefee + wfee;
    this.poCostingDetails.costing_total = costTotal ? Number(costTotal).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.makeDecimals();
  }
  makeDecimals() {
    this.poCostingDetails.invtotal = this.poCostingDetails.invtotal ? Number(this.poCostingDetails.invtotal).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.cf = this.poCostingDetails.cf ? Number(this.poCostingDetails.cf).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.duty = this.poCostingDetails.duty ? Number(this.poCostingDetails.duty).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.warehousefee = this.poCostingDetails.warehousefee ? Number(this.poCostingDetails.warehousefee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.freight = this.poCostingDetails.freight ? Number(this.poCostingDetails.freight).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.haulfee = this.poCostingDetails.haulfee ? Number(this.poCostingDetails.haulfee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.customsfee = this.poCostingDetails.customsfee ? Number(this.poCostingDetails.customsfee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.docfee = this.poCostingDetails.docfee ? Number(this.poCostingDetails.docfee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.cratefee = this.poCostingDetails.cratefee ? Number(this.poCostingDetails.cratefee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.tax = this.poCostingDetails.tax ? Number(this.poCostingDetails.tax).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.insurance = this.poCostingDetails.insurance ? Number(this.poCostingDetails.insurance).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.handlingfee = this.poCostingDetails.handlingfee ? Number(this.poCostingDetails.handlingfee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.servicefee = this.poCostingDetails.servicefee ? Number(this.poCostingDetails.servicefee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.fdeliveryfee = this.poCostingDetails.fdeliveryfee ? Number(this.poCostingDetails.fdeliveryfee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.otherfee = this.poCostingDetails.otherfee ? Number(this.poCostingDetails.otherfee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.ldeliveryfee = this.poCostingDetails.ldeliveryfee ? Number(this.poCostingDetails.ldeliveryfee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.hzmatfee = this.poCostingDetails.hzmatfee ? Number(this.poCostingDetails.hzmatfee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.wfpercentage = this.poCostingDetails.wfpercentage ? Number(this.poCostingDetails.wfpercentage).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.demurragefee = this.poCostingDetails.demurragefee ? Number(this.poCostingDetails.demurragefee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.rate = this.poCostingDetails.rate ? Number(this.poCostingDetails.rate).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.wfee = this.poCostingDetails.wfee ? Number(this.poCostingDetails.wfee).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.poCostingDetails.costing_total = this.poCostingDetails.costing_total ? Number(this.poCostingDetails.costing_total).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
  }
}
