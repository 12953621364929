<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h5 class="modal-title f-w-600" id="productModalLabel">{{ 'Title' | translate }}
            </h5>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
            </button>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="custom-row">
                <div class="form-row ">
                    <div class="col-lg-4 col-md-6 form-group">
                        <label for="">{{ 'Form_Inputs.Name_Label' | translate }}</label>
                        <input type="search" class="form-control" [(ngModel)]="device.name" name="name"
                            placeholder="{{ 'Form_Inputs.Name_Placeholder' | translate }}">
                    </div>
                    <div class="col-lg-4 col-md-6 form-group">
                        <label for="">{{ 'Form_Inputs.ILC_Label' | translate }}</label>
                        <input type="search" class="form-control" [(ngModel)]="device.ilc" name="ilc" placeholder="{{ 'Form_Inputs.ILC_Placeholder' | translate }}">
                    </div>
                    <div class="form-group col-lg-4 col-sm-12">
                        <label for="siteid">{{ 'Form_Inputs.Type_Label' | translate }}</label>
                        <select class="form-control" name="type" id="type" [(ngModel)]="device.type">
                            <option value="">{{ 'Form_Inputs.Type_Placeholder' | translate }}</option>
                            <!-- <option *ngFor="let item of typeList" [value]="item.Value">
                    {{item.Text}}</option> -->
                        </select>
                    </div>
                </div>
                <div class=" btn-group CsearchBtn1 CsearchBtn" role="group">
                    <button type="button" container="body" ngbTooltip="{{ 'Search_tooltip' | translate }}" class="btn btn-primary right-radius"
                        (click)="getDevice()">{{ 'Search_button' | translate }}</button>
                </div>
            </div>
            <div class="category-table custom-datatable">
                <div class="table-responsive">
                    <table class="custom-table">
                        <thead class="sticky-tab-head">
                            <tr class="custom-tr">
                                <th class="custom-th text-center">{{ 'Form_Table.Name_Column1' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Form_Table.Description_Column2' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Form_Table.Class_Column3' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Form_Table.ILC_Column4' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Form_Table.Make_Column5' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Form_Table.Manufacturer_Column6' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Form_Table.Model_Column7' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Form_Table.Serial_Column8' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Form_Table.Thirdpartycode_Column9' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Form_Table.Type_Column10' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Form_Table.Yearmanfatured_Column11' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Form_Table.Active_Column12' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Form_Table.createby_Column13' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Form_Table.Createdate_Column14' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Form_Table.updateby_Column15' | translate }}</th>
                                <th class="custom-th text-center">{{ 'Form_Table.UpdateDate_Column16' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="custom-tr" *ngFor="let item of deviceList">
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectDevice(item)">{{item.name}}</span></td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectDevice(item)">{{item.description}}</span></td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectDevice(item)">{{item.device_class}}</span></td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectDevice(item)">{{item.ilc}}</span>
                                </td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectDevice(item)">{{item.make}}</span></td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectDevice(item)">{{item.manufacturer}}</span></td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectDevice(item)">{{item.model}}</span></td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectDevice(item)">{{item.serial}}</span></td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectDevice(item)">{{item.tpcode}}</span></td>
                                <td class="custom-td text-left hyperlink"><span
                                        (click)="selectDevice(item)">{{item.type}}</span></td>
                                <td class="custom-td text-left hyperlink">{{item.yearmanufactured}}</td>
                                <td class="custom-td text-center"><span class="badge"
                                        title="{{item.active}}">{{item.active}}</span>
                                </td>
                                <td class="custom-td text-left">{{item.createby}}</td>
                                <td class="custom-td text-center text-nowrap">{{item.createdate |
                                    date:userdata.displaydatetimeformat}}
                                </td>
                                <td class="custom-td text-left">{{item.updateby}}</td>
                                <td class="custom-td text-center text-nowrap">{{item.updatedate |
                                    date:userdata.displaydatetimeformat}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                (click)="closeModal()">{{ 'Close_button' | translate }}</button>
        </div>
    </div>
</resize-border>