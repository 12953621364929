import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { accountServices } from '../../accounts/account.service';

@Component({
  selector: 'app-makepayment',
  templateUrl: './makepayment.component.html',
  styleUrls: ['./makepayment.component.scss']
})
export class MakepaymentComponent implements OnInit {
  fullScreen = false;
  @Input('id') id;
  @Input('totalcost') totalcost;
  @Input('dueamount') dueamount;
  @Input('invoiceno') invoiceno;

  userdata: any;
  appname = environment.APP_NAME;
  paymentMethodList: any = [];
  payment: any = {
    bankid: ''
  }
  totalTrCost: any = 0
  companyBanks: any = [];
  constructor(private activeModal: NgbActiveModal,
    private datepipe: DatePipe,
    private service: accountServices,
    private loader: NgxUiLoaderService,
    private toaster: NotificationService,
    private commonService: CommonServices,) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.commonService.getPaymentMethods().subscribe(res => {
      this.paymentMethodList = res;
    }, err => {
    });
  }
  getPaymentAccounts() {
    var type = '';
    this.paymentMethodList.forEach(item => {
      if (item.Value == this.payment.mop) {
        type = item.Value1 ? item.Value1 : '';
      }
    })
    this.commonService.getCompMOPBankList(this.payment.mop).subscribe(res => {
      this.companyBanks = res;
    }, err => {
    });
  }
  addPayment() {
    var paymentobj = {
      "id": this.id,
      "companyid": this.userdata.companyid,
      "payment_type": this.payment.payment_type,
      "dop": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "refno": this.invoiceno,
      "memo": this.payment.memo,
      "createby": this.userdata.email,
      "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "status": 'active',
      "isposted": true,
      "postedby": this.userdata.email,
      "posteddate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "coa_id": Number(this.payment.coa_id),
      "bankid": this.payment.bankid
    }
    environment.production ? '' : console.log(this.payment);
    this.loader.start();
    // return false;
    this.service.postVendPayment(paymentobj).subscribe(res => {
      environment.production ? '' : console.log(res)
      this.payment = {}
      this.loader.stop();
      this.toaster.Success('Record Saved')
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading customer list ', err);
    });
  }

  ngOnInit(): void {
    this.payment.amt = this.totalcost;
    this.payment.balance = this.totalcost - this.dueamount;
  }
  chagneAmt() {
    this.payment.balance = this.totalcost - this.payment.amt;
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }



}
