import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { interval, Subscription } from 'rxjs';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-empadhocpayments',
  templateUrl: './empadhocpayments.component.html',
  styleUrls: ['./empadhocpayments.component.scss']
})
export class EmpadhocpaymentsComponent implements OnInit {
  header: any;
  pos: any = {};
  userdata: any;
  appname = environment.APP_NAME;
  setCustomerValue: any;
  keyword = 'name';
  validatecustomer: any;
  paymentList: any = [];
  subscription: Subscription;
  employeeList: any = [];
  ref_type: any = '';
  empid:any=null;
  searchtxt: any = '';
  selectedReference: any = {};
  @ViewChild('SearchElement') SearchElement: ElementRef;
  constructor(private commonService: CommonServices,
    private loader: NgxUiLoaderService,
    private toastr:NotificationService,
    private datepipe: DatePipe
  ) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }

  ngOnInit(): void {
    this.getReferences();
  }

  getReferences() {
    if(!this.empid){
      // this.toastr.Error('Please select employee');
      return;
    }
    this.loader.start();
    this.commonService.getEmployeeAdHocPayRef(this.empid,this.ref_type, this.searchtxt).subscribe((data) => {
      this.loader.stop();
      this.paymentList = data;
      environment.production ? '' : console.log('employee list data ', data);
    }, (err) => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading status list ', err);
    }
    );
  }

  selectRef(data) {
    this.selectedReference = data;
  }
  preventEvent(event: Event): void {
    event.stopPropagation(); // Prevents event bubbling
    event.preventDefault();  // Prevents default action (if any)
  }

  ngAfterViewInit() {
    const source = interval(500);
    this.subscription = source.subscribe(val => {
      if (!this.SearchElement.nativeElement.focus()) {
        this.SearchElement.nativeElement.focus()
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    console.log('destroyed')
  }

}
