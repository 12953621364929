<!-- <resize-border [dragHolder]="header">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="productModalLabel">Supplier List
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body"> -->
<div class="padding-div">
  <form (keydown.enter)="getSupplier()">
    <div class="form-row">
      <div class="col-lg-9 col-md-9 form-group position-relative">
        <i class="fa fa-search hover-icon"></i><input #SearchElement style="padding-left:20px;" type="text"
          class="form-control" name="searchTxt" placeholder="Search by Supplier Name" [(ngModel)]="searchTxt"
          (click)="preventEvent($event)" (input)="getSupplier()">
      </div>
      <div class="col-lg-3 text-center position-relative">
        <div class="small-label">Local</div>
        <ng-toggle name="toggle" [(ngModel)]="islocal" [color]="{
          unchecked: '#939da2',
          checked: '#1e5598'
        }"></ng-toggle>
      </div>
      <!-- <div class="col-lg-3 col-md-6 form-group">
      <div class="form-group" style="display: flex; align-items: center;">
        <div style="margin-right: 10px;">Local</div>
        <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
          <label class="d-block" for="">
            <input class="radio_animated" [value]=true id="islocal" [(ngModel)]="islocal" type="radio" name="islocal">
            Yes
          </label>
          <label class="d-block" for="">
            <input class="radio_animated" [value]=false id="islocal1" [(ngModel)]="islocal" type="radio" name="islocal">
            No
          </label>
        </div>
      </div>
    </div> -->
    </div>
  </form>
  <div class="category-table custom-datatable mt-1">
    <div class="table-responsive">
      <table class="custom-table">
        <thead>
          <tr class="custom-tr">
            <th class="custom-th text-center">Name</th>
            <th class="custom-th text-center">POC</th>
            <th class="custom-th text-center">Payment Terms</th>
            <th class="custom-th text-center">Currency</th>
            <th class="custom-th text-center">Local</th>
          </tr>
        </thead>
        <tbody>
          <tr class="custom-tr" *ngFor="let item of supplierList">
            <td class="custom-td position-relative text-left">
              <div class="hyperlink" ngbDropdownItem (click)="selectSupplier(item)" [ngbTooltip]="Information"
                placement="bottom" tooltipClass="modal-dropdown-tooltip" [openDelay]="200">{{item.name}}</div>
              <ng-template #Information [positionTarget]="target">
                <div class="d-flex flex-column">
                  <div><b>Name :</b> {{item.name}}
                  </div>
                  <div><b>POC :</b> {{item.poc}}
                  </div>
                  <div><b>Payment Terms :</b> {{item.paymentterms}}</div>
                  <div><b>Currency :</b> {{item.currency}}</div>
                  <div><b>Local :</b> {{item.local}}</div>
                </div>
              </ng-template>
            </td>
            <td class="custom-td position-relative text-left">
              <div class="hyperlink" ngbDropdownItem (click)="selectSupplier(item)" #target [ngbTooltip]="Information"
                placement="bottom" tooltipClass="modal-dropdown-tooltip">{{item.poc}}</div>

            </td>
            <td class="custom-td position-relative text-left">
              <div class="hyperlink" (click)="selectSupplier(item)" ngbDropdownItem>{{item.paymentterms}}</div>
            </td>
            <td class="custom-td position-relative text-center">
              <div class="hyperlink" (click)="selectSupplier(item)" ngbDropdownItem>{{item.currency}}</div>
            </td>
            <td class="custom-td position-relative text-center">
              <div class="hyperlink" (click)="selectSupplier(item)" ngbDropdownItem>{{item.local}}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="no-results" *ngIf="supplierList.length == 0">Your search result yielded no results</div>
    </div>
  </div>
  <!-- </div>
  <div class="modal-footer justify-content-center">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
  </div>
</resize-border> -->
</div>