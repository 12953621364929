import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/service/language.service';
import { HttpClient } from '@angular/common/http';
import { FileUploader } from 'ng2-file-upload';
import { element } from 'screenfull';


@Component({
  selector: 'app-receivedchequetracking',
  templateUrl: './receivedchequetracking.component.html',
  styleUrls: ['./receivedchequetracking.component.scss']
})
export class ReceivedchequetrackingComponent implements OnInit {
  private langChangeSubscription: Subscription;
  fullScreen = false;
  receivePayment: any;
  @Input('params') params;
  tracking: any = {
  };
  userdata: any;
  appname: any = environment.APP_NAME;
  statusList: any = [];
  uploader: FileUploader;
  t_received_payment_files: any = [];
  filename: any;
  response: any;
  trackingLog: any = [];
  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    private http: HttpClient,
    private activeModal: NgbActiveModal,
    private loader: NgxUiLoaderService,
    private toastr: NotificationService,
    private service: CommonServices,
    private datepipe: DatePipe) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.service.getComboEnums('ReceivePaychequestatus').subscribe(res => {
      this.statusList = res;
      environment.production ? '' : console.log('status list', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading status list ', err);
    });
  }

  ngOnInit(): void {
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => {
      this.loadComponentTranslations(lang);
    });
    this.receivePayment = this.params;
    this.getTrackingLog();
  }
  initialize() {
    this.tracking = {
      status: '',
      notes: '',
      partynotified: false,
      reason: '',
      t_received_payment_files: []
    };
    this.initializeFileUploader();
  }
  private loadComponentTranslations(lang: string) {
    const translationFilePath = `./assets/i18n/accounts/receivables/chequereceived/${lang}.json`;
    this.http.get(translationFilePath).subscribe((translations: any) => {
      this.translate.setTranslation(lang, translations, true);
      this.translate.use(lang);
    });
  }

  closeModal(action) {
    this.activeModal.close(action);
  }

  initializeFileUploader() {
    this.uploader = new FileUploader({
      authTokenHeader: 'authorization',
      authToken: 'Bearer ' + this.userdata.token,
      url: environment.API_URL + 'api/Documents/UploadFiles',
      method: 'POST',
      removeAfterUpload: true,
      queueLimit: 100
    })
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('companyid', this.userdata.companyid);
      form.append('refno', this.receivePayment.number);
      form.append('createby', this.userdata.email);
      form.append('createdate', this.datepipe.transform(new Date(), this.userdata.postdatetimeformat));
      form.append('dtype', 'cheque-tracking-files');
    };
    this.response = '';
    this.uploader.response.subscribe(res => {
      environment.production ? '' : console.log('res ' + res);
      this.response = res.data;
    });
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onAfterAddingAll = (files: any) => {
      files.forEach(fileItem => {
        environment.production ? '' : console.log('file name: ' + fileItem.file.name);
        this.t_received_payment_files.push({
          "id": null,
          "rpid": this.receivePayment.rpid,
          "rptid": this.receivePayment.rptid ? this.receivePayment.rptid : '',
          "file_url": fileItem.file.name,
          "active": true,
          "createby": this.userdata.email,
          "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
        })
      });
    };
  }
  cancelAttachment() {
    this.t_received_payment_files = [];
  }
  selectedFileOnChanged(event: any) {
    environment.production ? '' : console.log(event.target.value);
    this.filename = event.target.value.split(/(\\|\/)/g).pop();
  }
  uploadFile() {
    this.t_received_payment_files.forEach((file, index) => {
      if (this.t_received_payment_files.length - 1 == index) {
        this.t_received_payment_files = [];
        this.getTrackingLog();
      }
      this.uploader.queue[index].onSuccess = function (response, status, headers) {
        if (status == 200) {
          let tempRes = JSON.parse(response);
          environment.production ? '' : console.log('upload', status);
        } else {
          // this.toastr.Success("File uploaded falid")
          environment.production ? '' : console.log('upload faild', status);
          // Swal.fire('', 'File uploaded', 'success');
        }
      };
      this.uploader.queue[index].upload();
    })
  }
  getTrackingLog() {
    this.initialize();
    this.loader.start();
    this.service.getReceivedChequeTracking(this.receivePayment.rpid).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        this.trackingLog = res;
        this.trackingLog.forEach(item => {
          item.files.forEach(element => {
            element.url = environment.DOC_URL + '/' + element.file_url;
          })
        })
      }
    }, err => {
      this.loader.stop();
    })
  }
  saveTracking() {
    if (this.tracking.status == null && this.tracking.status == '') {
      this.toastr.Error('Please select status');
      return;
    }
    this.tracking.rpid = this.receivePayment.rpid;
    this.tracking.companyid = this.userdata.companyid;
    this.tracking.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    this.tracking.createby = this.userdata.email;
    this.tracking.t_received_payment_files = this.t_received_payment_files;
    this.loader.start();
    this.service.postReceivedTracking(this.tracking).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          this.tracking = res.data;
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        if (this.t_received_payment_files.length > 0) {
          this.uploadFile();
        } else {
          this.getTrackingLog();
        }
      }
    }, err => {
      this.loader.stop();
    })
  }

}
