import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonServices } from 'src/app/shared/service/common.service';
import { environment } from 'src/environments/environment';
import { categoryDB } from '../../../../../shared/tables/category';

import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { DocumentsComponent } from 'src/app/components/modal/documents/documents.component';
import { LanguageService } from 'src/app/shared/service/language.service';
import { InventoryServices } from '../../../inventory.service';
import { AddProductComponent } from '../add-product.component';
@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentTabComponent implements OnInit {
  public closeResult: string;
  public categories = []
  userdata: any;
  appname = environment.APP_NAME;
  docList: any = [];
  title: any = ''
  docType: any = ''
  fieldsetDisabled = false;
  reference: any
  format: any;
  product: any;
  isView: boolean = false;
  lastaction: any;
  docURL:any;
  private langChangeSubscription: Subscription;
  constructor(private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private route: ActivatedRoute,
    private maincomponent: AddProductComponent,
    private translate: TranslateService,
    private languageService: LanguageService,
    private http: HttpClient,
    private commonService: CommonServices,
    private loader: NgxUiLoaderService,
    private service: InventoryServices,) {
    this.categories = categoryDB.category;

    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.product = maincomponent.product;
      this.docURL=environment.DOC_URL+'/'+this.userdata.companyid+'/'
    this.format = this.userdata.date_format;
    this.lastaction = this.route.snapshot.queryParamMap.get('action');
    if (this.lastaction == 'view') {
      this.fieldsetDisabled = true;
    } else if (this.lastaction == 'edit') {
      this.fieldsetDisabled = false;
    }
    this.getProductDocuments()
  }

  ngOnInit(): void {
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => {
      this.loadComponentTranslations(lang);
    });
  }

  private loadComponentTranslations(lang: string) {
		const translationFilePath = `./assets/i18n/inventory/productitems/documents/${lang}.json`;
		this.http.get(translationFilePath).subscribe((translations:any) => {
		  this.translate.setTranslation(lang, translations, true);
		  this.translate.use(lang);
		});
	}
  

  sortDir = 1;//1= 'ASE' -1= DSC
onSortClick(event,colum) {
	let target = event.currentTarget,
		childtarget = target.querySelector('i'),
		classList = childtarget.classList;

	if (classList.contains('fa-chevron-up')) {
		classList.remove('fa-chevron-up');
		classList.add('fa-chevron-down');
		target.classList.add('Sorted');
		this.sortDir = -1;
	} else {
		classList.add('fa-chevron-up');
		classList.remove('fa-chevron-down');
		target.classList.remove('Sorted');
		this.sortDir = 1;
	}
	this.sortArr(colum);
}

sortArr(colName: any) {
	this.docList.sort((a, b) => {
		let valueA = (a[colName] && typeof a[colName] === 'string') ? a[colName].toLowerCase() : a[colName];
		let valueB = (b[colName] && typeof b[colName] === 'string') ? b[colName].toLowerCase() : b[colName];

if (!valueA && !valueB) {
	return 0; 
}
if (!valueA) {
	return -this.sortDir; 
}
if (!valueB) {
	return this.sortDir; 
}

if (valueA > valueB) {
	return this.sortDir;
}
if (valueA < valueB) {
	return -this.sortDir;
}
return 0;
});
}

//#endregion Sorting Table Header


  getProductDocuments() {
    this.loader.start();
    this.service.getProductDocuments(this.product.id).subscribe(res => {
      this.loader.stop();
      this.docList = res;
    }, err => {
      this.loader.stop();
    });
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  viewDocument(doc) {
    const url = environment.DOC_URL + '/' + this.userdata.companyid + '/' + doc.name;
    window.open(url, '_blank');
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  uploadDoc(item, action) {
    var modalRef = null;
    if (action == '') {
      modalRef = this.modalService.open(DocumentsComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
      modalRef.componentInstance.refno = this.product.ilc;
      modalRef.componentInstance.id = this.product.id;
      modalRef.result.then((result) => {
        this.getProductDocuments()
        environment.production ? '' : console.log(result);
      }).catch((error) => {
        this.getProductDocuments()

        environment.production ? '' : console.log(error);
      });
    }
    if (action == 'edit') {
      modalRef = this.modalService.open(DocumentsComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
      modalRef.componentInstance.refno = this.product.ilc;
      // modalRef.componentInstance.id = this.product.id;
      modalRef.componentInstance.id = this.docList.id
      modalRef.result.then((result) => {
        this.getProductDocuments()
        environment.production ? '' : console.log(result);
      }).catch((error) => {
        this.getProductDocuments()
        environment.production ? '' : console.log(error);
      });
    }
  }
  getDocList() {
    this.commonService.getDocList(this.maincomponent.product.ilc).subscribe(res => {
      this.docList = res;
      environment.production ? '' : console.log('doc list  ', this.docList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading doc list ', err);
    });
  }
  // getInventoryItemsDetials() {
  //   this.service.getInventoryItemsDetials(this.maincomponent.product.id).subscribe(res => {
  //     this.docList = res;
  //     environment.production ? '' : console.log('product type  ', res);
  //   }, err => {
  //     environment.production ? '' : console.log('Error : While loading product type data', err);
  //   });
  // }

}
