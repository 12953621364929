<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">Receive Payments</h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="needs-validation">
        <div class="form row">
          <h4 class="col-sm-12 text-center">Customer Name : {{customer.name}}</h4>
          <div class=" col-sm-12 text-center mb-2">
            <span class="badge badge-secondary" (click)="openCreditModal()" [class.red]="dueInv.availbalance < 0"
              [class.green]="dueInv.availbalance > 0">Available Credits: {{dueInv.availbalance}}</span>
          </div>
          <div class="form-group col-md-3 col-sm-12">
            <label for="">Date of Payment</label>
            <mat-form-field>
              <input matInput [matDatepicker]="picker3" placeholder="Choose a date" [(ngModel)]="payment.trandate"
                name="trandate">
              <mat-datepicker-toggle matSuffix [for]="picker3">
              </mat-datepicker-toggle>
              <mat-datepicker #picker3></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="form-group col-md-3 col-sm-12">
            <label for="">Payment method</label>
            <select class="form-control" required="" name="mop" [(ngModel)]="payment.mop"
              (change)="selectPaymentType()" #paymentmethod="ngModel"
              [ngClass]="{ 'is-invalid': paymentmethod.invalid && paymentmethod.errors , 'is-valid': paymentmethod.valid}">
              <option value="">Select method</option>
              <option *ngFor="let item of paymentMethodList" [value]="item.Value">
                {{item.Text}}</option>
            </select>
            <div *ngIf="paymentmethod.invalid && paymentmethod.errors" class="invalid-feedback d-block">
              <div *ngIf="paymentmethod.errors.required">Payment method is
                required</div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 form-group">
            <label for="">Cash/Bank</label>
            <select class="form-control" required="" name="bankid" [(ngModel)]="payment.bankid" #bankname="ngModel"
              [ngClass]="{ 'is-invalid': bankname.invalid && bankname.errors , 'is-valid': bankname.valid}">
              <option value="">Select Cash/Bank</option>
              <option *ngFor="let item of companyBanks" [value]="item.Value">
                {{item.Text}}</option>
            </select>
            <div *ngIf="bankname.invalid && bankname.errors" class="invalid-feedback d-block">
              <div *ngIf="bankname.errors.required">Bank name is required</div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 form-group" *ngIf="isNumberShow">
            <label for="">Cheque#</label>
            <input type="text" class="form-control" placeholder="Cheque#" name="paymentamt"
              [(ngModel)]="payment.number">
          </div>
          <div class="col-lg-3 col-md-6 form-group" *ngIf="isNumberShow">
            <label for="">Cheque Details</label>
            <input type="text" class="form-control" placeholder="Bank name/other details" name="cheque_details"
              [(ngModel)]="payment.cheque_details">
          </div>
          <div class="col-lg-3 col-md-6 form-group" *ngIf="isNumberShow">
            <label for="">Cheque Date</label>
            <mat-form-field class="bg-white">
              <input matInput [matDatepicker]="picker27" placeholder="Choose a date" name="cheque_date" [(ngModel)]="payment.cheque_date">
              <mat-datepicker-toggle matSuffix [for]="picker27"></mat-datepicker-toggle>
              <mat-datepicker #picker27></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="form-group col-md-3 col-sm-12">
            <label for="">Amount</label>
            <input type="number" name="tendered_amt" id="" class="form-control" [(ngModel)]="payment.tendered_amt"
              placeholder="Amount" (change)="updatePayAmt()" #amount="ngModel"
              [ngClass]="{ 'is-invalid': amount.invalid && amount.errors , 'is-valid': amount.valid}" required>
            <div *ngIf="amount.invalid && amount.errors" class="invalid-feedback d-block">
              <div *ngIf="amount.errors.required">Amount is
                required</div>
            </div>
          </div>
          <div class="form-group col-md-3 col-sm-12">
            <label for="">Change/Overpayment</label>
            <input type="text" name="change" id="" class="form-control" placeholder="Change/Overpayment"
              [(ngModel)]="payment.change" readonly>
          </div>
          <div class="form-group col-md-3 col-sm-12">
            <label for="">Received Amount ({{userdata.currency}})</label>
            <input type="number" readonly class="form-control" name="receivedbase_amt"
              value="{{receivedBaseAmount}}">
          </div>
          <div class="form-group col-md-3 col-sm-12">
            <label for="">FX Loss/Gain</label>
            <input type="number" class="form-control" name="fx_loss_gain" [(ngModel)]="fxLossGain">
          </div>
          <div class="form-group col-md-3 col-sm-12">
            <label for="">Leave Credit/Change on Account ? </label>
            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <label class="d-block" for="showMultiple">
                <input class="radio_animated" [(ngModel)]="payment.leave_credit" [value]=true id="showMultiple11"
                  type="radio" name="rdo-ani11">
                Yes
              </label>
              <label class="d-block" for="showMultiple1">
                <input class="radio_animated" [(ngModel)]="payment.leave_credit" [value]=false id="showMultiple12"
                  type="radio" name="rdo-ani12">
                No
              </label>
            </div>
          </div>
          <div class="form-group col-md-3 col-sm-12">
            <label for="">Payment Type</label>
            <select class="form-control" required="" name="payment_type" [(ngModel)]="payment.payment_type"
              #paymentype="ngModel"
              [ngClass]="{ 'is-invalid': paymentype.invalid && paymentype.errors , 'is-valid': paymentype.valid}">
              <option *ngFor="let item of paymentTypeList" [value]="item.stringValue">
                {{item.Text}}</option>
            </select>
            <div *ngIf="paymentype.invalid && paymentype.errors" class="invalid-feedback d-block">
              <div *ngIf="paymentype.errors.required">Payment Type is
                required</div>
            </div>
          </div>
          <div class="form-group col-md-3 col-sm-12">
            <label for="">Memo/Narration</label>
            <input type="text" name="memo" id="" required="" class="form-control" [(ngModel)]="payment.memo"
              placeholder="Memo/Narration" #memo="ngModel"
              [ngClass]="{ 'is-invalid': memo.invalid && memo.errors , 'is-valid': memo.valid}">
            <div *ngIf="memo.invalid && memo.errors" class="invalid-feedback d-block">
              <div *ngIf="memo.errors.required">Memo is
                required</div>
            </div>
          </div>
          <div class="form-group col-md-3 col-sm-12">
            <label for="">Notify Customer </label>
            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <label class="d-block" for="showMultiple">
                <input class="radio_animated" [value]=true [(ngModel)]="payment.notify_customer" id="showMultiple33"
                  type="radio" name="rdo-ani33">
                Yes
              </label>
              <label class="d-block" for="showMultiple1">
                <input class="radio_animated" [value]=false [(ngModel)]="payment.notify_customer" id="showMultiple44"
                  type="radio" name="rdo-ani44">
                No
              </label>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="form-group">
              <label>Currency </label>
              <div class="input-group mb-3">
                <select class="form-control" id="currency" required="" (change)="selectROE()" name="currency"
                  [(ngModel)]="dueInv.currency" #currencyname="ngModel"
                  [ngClass]="{ 'is-invalid': currencyname.invalid && currencyname.errors , 'is-valid': currencyname.valid}">

                  <option value="">Select currency</option>
                  <option *ngFor="let item of currencyList" [value]="item.Value">
                    {{item.Text}}
                  </option>
                </select>
                <div *ngIf="currencyname.invalid && currencyname.errors" class="invalid-feedback d-block">
                  <div *ngIf="currencyname.errors.required">Currency is
                    required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 form-group">
            <label for="">XR</label>
            <div class="input-group">
              <input type="text" class="form-control text-right" placeholder="0" name="ROE" [(ngModel)]="payment.roe"
                #roe="ngModel" required="" #exchnagerate="ngModel" (change)="calculateLineItemTotal()"
                [ngClass]="{ 'is-invalid': exchnagerate.invalid && exchnagerate.errors , 'is-valid': exchnagerate.valid}">
              <div class="input-group-append">
                <span class="input-group-text btn-primary cursor-pointer" id="basic-addon2" (click)="getCurrentROE()"><i
                    class="fa fa-refresh"></i>
                </span>
              </div>
            </div>
          </div>

          <div class="form-group col-sm-12">
            <label for="">Notes</label>
            <textarea class="form-control" rows="5" [readonly]="false" debounce="0" name="notes"
              [(ngModel)]="payment.notes"></textarea>
          </div>
          <div class="category-table custom-datatable col-sm-12">
            <div class="table-responsive2">
              <table class="custom-table">
                <tr class="custom-tr">
                  <th class="custom-th text-center">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" name="allCheck" value="" id=""
                        [(ngModel)]="allCheck" (change)="selectAll($event)">
                      <label class="form-check-label" for="">
                        Ref#
                      </label>
                    </div>
                  </th>
                  <th class="custom-th text-center">Type</th>
                  <th class="custom-th text-center">Project/Job Ref</th>
                  <th class="custom-th text-center">Date</th>
                  <th class="custom-th text-center">Status</th>
                  <th class="custom-th text-center">Curr.</th>
                  <th class="custom-th text-center">Total Due</th>
                  <th class="custom-th text-center">Total Due({{userdata.currency}})</th>
                  <th class="custom-th text-center">Paid Amount</th>
                  <th class="custom-th text-center">Base Total ({{userdata.currency}})</th>
                  <th class="custom-th text-center">Paid Amt({{userdata.currency}})</th>
                  <th class="custom-th text-center">Actions</th>
                </tr>
                <ng-container *ngIf="dueInv">
                  <tr class="custom-tr" *ngFor="let item of duepayments; let j = index; ">
                    <td class="custom-td text-center pl-2">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" name="chk_{{j}}"
                          [(ngModel)]="item.checked" (change)="calculateLineItemTotal()" id="">
                        <label class="form-check-label" for="">
                          {{item.refno}}
                        </label>
                      </div>
                    </td>
                    <td class="custom-td text-center">
                      {{item.type}}
                    </td>
                    <td class="custom-td text-left">
                      {{item.projref}}
                    </td>
                    <td class="custom-td text-center">{{item.invdate}}
                    </td>
                    <td class="custom-td text-center">{{item.status}}
                    </td>
                    <td class="custom-td text-center">{{item.currency}}
                    </td>
                    <td class="custom-td text-right">{{item.bal}}</td>
                    <td class="custom-td text-right">{{item.duebasetotal}}</td>
                    <td class="custom-td text-right">
                      <input class="form-control" (focusout)="change(item)" name="item_{{j}}"
                        [(ngModel)]="item.paidamt">
                    </td>
                    <td class="custom-td text-right">{{item.basetotal}}
                    </td>
                    <td class="custom-td text-right">{{item.paidbasecurrtotal}}
                    </td>
                    <td class="custom-td text-center">
                      <div ngbDropdown class="d-inline-block ActionCell" container="body">
                        <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                        <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                          <li><span (click)="print(item)" ngbDropdownItem>Print</span></li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr class="custom-tr">
                    <td class="custom-td text-right mr-2" colspan="6"><b>Totals</b></td>
                    <td class="custom-td text-right mr-2"><b>{{ dueTotal }}</b></td>
                    <td class="custom-td text-right mr-2"><b>{{ dueBaseTotal }}</b></td>
                    <td class="custom-td text-right pr-3" style="text-align: right !important;">
                      <b>{{paidTotal}}</b>
                    </td>
                    <td class="custom-td text-right mr-2"><b>{{ baseTotal }}</b></td>
                    <td class="custom-td text-right pr-3" style="text-align: right !important;">
                      <b>{{ paidCurrTotal}}</b>
                    </td>
                  </tr>
                </ng-container>
              </table>
            </div>
          </div>
          <!-- <div class="form-group col-md-4 col-sm-12">
                <label for="">Select Files</label>
                <input type="file" name="" id="" class="form-control" placeholder="Select Files">
            </div> -->
        </div>
      </form>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button type="button" class="btn btn-primary" (click)="addPayment()">Submit</button>
    </div>
  </div>
  <ngx-ui-loader></ngx-ui-loader>
</resize-border>