<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">Receive Payment</h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-lg-3 col-md-3 form-group">
          <label for="">Invoice: <b>{{params.refno}}</b></label>
        </div>
        <div class="col-lg-3 col-md-3 form-group">
          <label for="">Customer: <b>{{params.companyname}}</b></label>
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <div class="available-credit" *ngIf="isShowAvailableCredit" [class.red]="customer.availbalance <0"
            [class.green]="customer.availbalance >0" (click)="applyCredit()">
            <div clas="hover-value">Available Credit: <span *ngIf="customer">{{customer.availbalance}}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 form-group">
          <label for="">Total Due: <b>{{params.bal}} {{params.currency}}</b></label>
        </div>
        <div class="col-lg-3 col-md-3 form-group">
          <label for="">Base Total: <b>{{baseTotal}} {{userdata.currency}}</b></label>
        </div>
        <div class="col-lg-3 col-md-3 form-group">
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <label for="">Date of Payment </label>
          <mat-form-field class="bg-white">
            <input matInput [matDatepicker]="picker26" placeholder="Choose a date" [(ngModel)]="payment.trandate">
            <mat-datepicker-toggle matSuffix [for]="picker26"></mat-datepicker-toggle>
            <mat-datepicker #picker26></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <label>Payment Method </label>
            <div class="input-group mb-3">
              <select class="form-control" required="" name="mop" [(ngModel)]="payment.mop"
                (change)="selectPaymentType();">
                <option value="">Select Payment Method</option>
                <option *ngFor="let item of paymentMethodList" [value]="item.Value">
                  {{item.Text}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <label>Cash/Bank</label>
            <div class="input-group mb-3">
              <select class="form-control" required="" name="bank" [(ngModel)]="payment.bankid">
                <option value="">Select Cash/Bank</option>
                <option *ngFor="let item of companyBanks" [value]="item.Value">
                  {{item.Text}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="isNumberShow">
          <label for="">Cheque#</label>
          <input type="text" class="form-control" placeholder="Cheque#" name="cheque_number"
            [(ngModel)]="payment.number">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="isNumberShow">
          <label for="">Cheque Details</label>
          <input type="text" class="form-control" placeholder="Bank name/other details" name="cheque_details"
            [(ngModel)]="payment.cheque_details">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="isNumberShow">
          <label for="">Cheque Date</label>
          <mat-form-field class="bg-white">
            <input matInput [matDatepicker]="picker27" placeholder="Choose a date" [(ngModel)]="payment.cheque_date">
            <mat-datepicker-toggle matSuffix [for]="picker27"></mat-datepicker-toggle>
            <mat-datepicker #picker27></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <div class="form-group">
            <label>Currency </label>
            <div class="input-group mb-3">
              <select class="form-control" id="currency" required="" (change)="selectROE()" name="currency"
                [(ngModel)]="payment.currency" #currencyname="ngModel"
                [ngClass]="{ 'is-invalid': currencyname.invalid && currencyname.errors , 'is-valid': currencyname.valid}">
                <option value="">Select currency</option>
                <option *ngFor="let item of currencyList" [value]="item.Value">
                  {{item.Text}}
                </option>
                <div *ngIf="currencyname.invalid && currencyname.errors" class="invalid-feedback d-block">
                  <div *ngIf="currencyname.errors.required">Currency is
                    required</div>
                </div>
              </select>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <label for="">XR</label>
          <div class="input-group">
            <input type="text" class="form-control text-right" placeholder="0" name="ROE" [(ngModel)]="payment.roe"
              #roe="ngModel" required="" #exchnagerate="ngModel"
              [ngClass]="{ 'is-invalid': exchnagerate.invalid && exchnagerate.errors , 'is-valid': exchnagerate.valid}"
              (change)="updatePayAmt()">
            <div class="input-group-append">
              <span class="input-group-text btn-primary cursor-pointer" id="basic-addon2" (click)="getCurrentROE()"><i
                  class="fa fa-refresh"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <label for="">Amount</label>
          <input type="number" class="form-control" name="amt" [(ngModel)]="payment.tendered_amt"
            (change)="updatePayAmt()">
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <label for="">Change/OverPayment</label>
          <input type="text" class="form-control" placeholder="Change/Overpayment" [(ngModel)]="payment.change"
            readonly>
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <label for="">Received Amount({{userdata.currency}})</label>
          <input type="number" readonly class="form-control" name="paying_amt" value="{{payment.receivedbaseamount}}">
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <label for="">FX Loss/Gain</label>
          <input type="number" class="form-control" name="amt" [(ngModel)]="payment.fx_loss_gain"
            (change)="updatePayAmt()">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <label>Leave Credit/ Change a Account</label>
            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <label class="d-block" for="showMultiple">
                <input class="radio_animated" [value]=true [(ngModel)]="payment.leave_credit" id="changeonaccount"
                  type="radio" name="rdo-changeonaccount">
                Yes
              </label>
              <label class="d-block" for="showMultiple1">
                <input class="radio_animated" [value]=false [(ngModel)]="payment.leave_credit" id="changeonaccount1"
                  type="radio" name="rdo-changeonaccount1">
                No
              </label>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <label for="">Type</label>
          <select class="form-control" required="" name="site" [(ngModel)]="payment.payment_type">
            <option *ngFor="let item of paymentTypeList" [value]="item.stringValue">
              {{item.Text}}</option>
          </select>
        </div>
        <div class="col-lg-6 col-md-6 form-group">
          <label for="">Memo/Narration</label>
          <input type="text" class="form-control" [(ngModel)]="payment.memo" placeholder="Memo/Narration">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <label>Notify Customer</label>
            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <label class="d-block" for="showMultiple">
                <input class="radio_animated" value='1' [(ngModel)]="payment.notify" id="showMultiple33" type="radio"
                  name="rdo-ani33">
                Yes
              </label>
              <label class="d-block" for="showMultiple1">
                <input class="radio_animated" value='0' [(ngModel)]="payment.notify" id="showMultiple44" type="radio"
                  name="rdo-ani44">
                No
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button type="button" class="btn btn-primary" (click)="addPayment()">Save</button>
    </div>
  </div>
</resize-border>