import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ProjjoblistmodalComponent } from './projjoblistmodal.component';



@NgModule({
    declarations: [ProjjoblistmodalComponent],
    imports:[HttpClientModule, 
        TranslateModule.forRoot({ 
         loader: { 
         provide: TranslateLoader, 
         useFactory: HttpLoaderFactory, 
         deps: [HttpClient] 
         } 
         }) 
         
        ,CommonModule, FormsModule, NgbModule,  NgbTooltipModule],
    exports: [ProjjoblistmodalComponent],
})
export class ProjjoblistModule {}
export function HttpLoaderFactory(http: HttpClient) { 
    return new TranslateHttpLoader(http); 
   } 
    