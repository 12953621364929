<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">{{title}}
      </h5>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="dragDrop">
        <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            {{ 'Upload_Section.Title' | translate }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div class="clearfix"></div>
            <!-- <button class="btn btn-primary" type="button" (click)="openFileSelector()">Browse
            Files</button> -->
            <!-- <input type="file" #fileImportInput name="CSV File Upload" 
            (change)="fileChangeListener($event)" accept=".csv"> -->
            <div class="col-md-6">
              <div class="ImportBlock">
                <div class="head">
                  <img src="https://img.icons8.com/color/48/000000/csv.png" />
                  <span class="text-muted"> {{ 'Upload_Section.Button_Label' | translate }}</span>
                </div>
                <div class="form-group">
                  <input class="form-control" accept=".csv" id="csv" type="file" (change)="onFileSelect($event.target)"
                    name="myfile">
                </div>
              </div>
            </div>
          </ng-template>

        </ngx-file-drop>
        <div class="upload-table">
          <table class="table custom-table">
            <thead>
              <tr class="custom-tr">
                <th class="custom-th">{{ 'Rules_Section.Title' | translate }}</th>
              </tr>
            </thead>
            <tbody class="upload-name-style">
              <!-- <tr class="custom-tr" *ngFor="let item of files; let i=index">
              <td class="custom-td"><strong>{{ item.relativePath }}</strong></td>
            </tr> -->
              <tr class="custom-tr" *ngFor="let item of rulelist; let i=index">
                <td class="custom-td"><strong>{{ item.name }}</strong></td>
              </tr>


            </tbody>
          </table>
        </div>

      </div>

    </div>

    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">{{ 'Buttons.Close_button' | translate }}</button>
      <button class="btn btn-primary" type="button">{{ 'Buttons.Import_button' | translate }}</button>
    </div>
  </div>
</resize-border>