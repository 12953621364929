import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { CommonServices } from 'src/app/shared/service/common.service';
import { LanguageService } from 'src/app/shared/service/language.service';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { SalesServices } from '../../Sales/sales.service';
import { ModalContactsComponent } from '../contacts/contacts.component';

@Component({
  selector: 'app-addcontact',
  templateUrl: './addcontact.component.html',
  styleUrls: ['./addcontact.component.scss']
})
export class CustomerContactsModalComponent implements OnInit {
  fullScreen = false;
  companyLogo: any = 'http://ezybooks.net/docs/logos/';
  format: any;
  modalRef:any
  userdata: any;
   
private langChangeSubscription: Subscription; 

  appname = environment.APP_NAME;
  contact: any = {
    loyalitypoints: 0,
    istech: false,
    notifyme: false,
    soa: false,
    isprimary: false,
    active: true
  }
  @Input('customerid') customerid;
  contactList: any = [];
  selectedContact: any;
  constructor(
    private translate: TranslateService, 
private languageService: LanguageService, 
private http: HttpClient, 
    private activeModal: NgbActiveModal,
    private loader: NgxUiLoaderService,
    private modalService: NgbModal,
    private commonService: CommonServices,
    private toastr: NotificationService,
    private datepipe: DatePipe, private service: SalesServices) {
    //#region userdata block
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.format = this.userdata.date_format
    this.companyLogo += this.userdata.logo;
    MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
    //#endregion userdata block
  }

  ngOnInit(): void {
     
this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
	this.loadComponentTranslations(lang); 
}); 
  }

  private loadComponentTranslations(lang: string) { 
    const translationFilePath = `./assets/i18n/sales/invoices/addcontactmodal/${lang}.json`; 
    this.http.get(translationFilePath).subscribe((translations:any) => { 
    this.translate.setTranslation(lang, translations, true); 
    this.translate.use(lang); 
    }); 
     } 

  addContact() {
    this.contact.customerid = this.customerid;
    this.contact.companyid = this.userdata.companyid;
    this.contact.createby = this.userdata.email;
    this.contact.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    this.contact.contactid = this.contact.contactid ? this.contact.contactid : null;
    this.loader.start();
    this.service.postContact(this.contact).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.closeModal('save');
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading customer contact ', err);
    });
  }

  openContact() {
    this.modalRef = this.modalService.open(ModalContactsComponent, { size: 'xl', });
    this.modalRef.result.then((result: any) => {
      if (result != undefined) {
        this.contact.name = result.name;
        this.contact.email = result.email;
        this.contact.phone = result.phone ? result.phone : result.mobile;
        this.contact.notes = result.notes;
        this.contact.contactid = result.id;
      }
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }

  closeModal(action) {
    this.activeModal.close(action);
  }

}
