<div class="padding-div">
    <form (keydown.enter)="getProjJobslist()" autocomplete="off">
        <div class="form-row">
            <div class="col-lg-12 col-md-12 position-relative">
                <i class="fa fa-search hover-icon"></i><input  id="searchInput" type="search" class="form-control"
                    style="padding-left:20px" placeholder="{{ 'Projjoblistmodal.Search_Placeholder' | translate }}" name="searchtxt" [(ngModel)]="searchtxt" (input)="getProjJobslist()" autocomplete="off" autocorrect="off" spellcheck="false">
            </div>
        </div>
    </form>
    <div class="category-table custom-datatable mt-1">
        <div class="table-responsive">
            <table class="custom-table">
                <tr class="custom-tr">
                    <th class="custom-th text-center">{{ 'Projjoblistmodal.Table.Ezb_Ref_coloum' | translate }}</th>
                    <th class="custom-th text-center">{{ 'Projjoblistmodal.Table.ThirdParty_ref' | translate }}</th>
                    <th class="custom-th text-center" style="width: 200px;">{{ 'Projjoblistmodal.Table.ProjectName_coloum' | translate }}</th>
                    <th class="custom-th text-center">{{ 'Projjoblistmodal.Table.Type_coloum' | translate }}</th>
                    <th class="custom-th text-center">{{ 'Projjoblistmodal.Table.Customer_coloum' | translate }}</th>
                    
                    <th class="custom-th text-center">{{ 'Projjoblistmodal.Table.Site_coloum' | translate }}</th>
                    <th class="custom-th text-center">{{ 'Projjoblistmodal.Table.Tech_Coloum' | translate }}</th>
                    <th class="custom-th text-center">{{ 'Projjoblistmodal.Table.Service_notes_coloum' | translate }}</th>
                </tr>
                <tr class="custom-tr" *ngFor="let item of jobList">
                    <td class="custom-td position-relative text-left">
                        <div class="hyperlink" (click)="selectJob(item)" ngbDropdownItem [ngbTooltip]="Information"
                            placement="bottom top" tooltipClass="modal-dropdown-tooltip" [openDelay]="200">{{item.proj}}
                        </div>
                    </td>
                    <td class="custom-td position-relative text-left">
                        <div class="hyperlink" (click)="selectJob(item)" ngbDropdownItem [ngbTooltip]="Information"
                            placement="bottom top" tooltipClass="modal-dropdown-tooltip" [openDelay]="200">
                            {{item.tpcode}}</div>
                        <ng-template #Information>
                            <div class="d-flex flex-column">
                                <div><b>Ezb Ref# :</b> {{item.proj}}
                                </div>
                                <div><b>Third Party Ref# :</b> {{item.tpcode}}
                                </div>
                                <div><b>Type :</b> {{item.type}}</div>
                                <div><b>Customer :</b> {{item.customername}}</div>
                                <div><b>Scope :</b> {{item.name}}</div>
                                <div><b>Site :</b> {{item.siteother}}</div>
                                <div><b>Tech :</b> {{item.assignto}}</div>
                                <div><b>Service Notes :</b> {{item.servicenotes}}</div>
                            </div>
                        </ng-template>
                    </td>
                    <td class="custom-td position-relative text-left"  style="width: 200px;">
                        <div class="hyperlink" (click)="selectJob(item)" ngbDropdownItem [ngbTooltip]="Information"
                        placement="bottom top" tooltipClass="modal-dropdown-tooltip" [openDelay]="200">{{item.name}}</div>
                    </td>
                    <td class="custom-td position-relative text-left">
                        <div class="hyperlink" (click)="selectJob(item)" (mouseenter)="tooltip3.toggle()"
                            ngbDropdownItem>{{item.type}}</div>
                    </td>

                    <td class="custom-td position-relative text-left">
                        <div class="hyperlink" (click)="selectJob(item)" (mouseenter)="tooltip3.toggle()"
                            ngbDropdownItem>{{item.customername}}</div>
                    </td>
                   
                    <td class="custom-td position-relative text-left">
                        <div class="hyperlink" (click)="selectJob(item)" ngbDropdownItem>{{item.siteother}}</div>
                    </td>
                    <td class="custom-td position-relative text-left">
                        <div class="hyperlink" (click)="selectJob(item)" ngbDropdownItem>{{item.assignto}}</div>
                    </td>
                    <td class="custom-td position-relative text-left">
                        <div class="hyperlink" (click)="selectJob(item)" ngbDropdownItem>{{item.servicenotes}}</div>
                    </td>
                </tr>
            </table>
            <div class="no-results" *ngIf="jobList.length == 0">{{ 'Projjoblistmodal.Result' | translate }}</div>
        </div>

    </div>
</div>