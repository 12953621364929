import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { interval, Subscription } from 'rxjs';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-sourcelist',
  templateUrl: './sourcelist.component.html',
  styleUrls: ['./sourcelist.component.scss']
})
export class SourcelistModalComponent implements OnInit {
  @Input() sourcetype: string;
  pos: any = {};
  userdata: any;
  appname = environment.APP_NAME;
  @ViewChild('SearchElement') SearchElement: ElementRef;
  keyword = 'name';
  sourceList: any = []
  selectedSource: any;
  typeList: any = []
  searchtxt: any = '';
  subscription: Subscription;
  constructor(private activeModal: NgbActiveModal,
    private commonServices: CommonServices,
    private loader: NgxUiLoaderService,
    private datePipe: DatePipe,
    private toastr: NotificationService,
  ) {
    console.log('sourcelist')
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }

  getSourceList() {
    if (this.searchtxt == undefined || this.searchtxt == null || this.searchtxt == '') {
      return;
    }
    // if (!this.sourcetype || this.sourcetype.trim() === '') {
    //   this.toastr.Error('Please select source type');
    //   return;
    // }
    if (this.searchtxt.length < 3) {
      return;
    }
    this.loader.start();
    this.commonServices.getSourceList(this.sourcetype, this.searchtxt).subscribe(res => {
      this.loader.stop();
      this.sourceList = res;
    }, err => {
      this.loader.stop();
    });
  }
  selectSource(item) {
    this.selectedSource = item;
    this.searchtxt = '';
    this.sourceList = [];
  }

  ngAfterViewInit() {
    const source = interval(500);
    this.subscription = source.subscribe(val => {
      if (!this.SearchElement.nativeElement.focus()) {
        this.SearchElement.nativeElement.focus()
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    console.log('destroyed')
  }


  closeModal() {
    this.activeModal.close(this.selectedSource);
  }
  ngOnInit(): void {
    // this.customer.name = this.type ? this.type : '';
    this.getSourceList();
  }

}
