import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxPrintElementService } from 'ngx-print-element';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { CommonServices } from 'src/app/shared/service/common.service';
import { LanguageService } from 'src/app/shared/service/language.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-genericprint',
  templateUrl: './genericprint.component.html',
  styleUrls: ['./genericprint.component.scss'],
})
export class GenericprintComponent implements OnInit {
  fullScreen = false;
  @Input('name') name;
  @Input('id') id;
  @Input('reviewer_type') reviewer_type;
  @Input('type') type;
  @Input('typename') typename;
  @Input('fromdate') fromdate;
  @Input('todate') todate;
  @Input('status') status;
  @Input('assignto') assignto;
  @Input('params') params;
  ticketsBatchPrintList: any = [];
  userdata: any;
  private langChangeSubscription: Subscription;

  appname = environment.APP_NAME;
  printURL: any;
  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    private http: HttpClient,
    private modalService: NgbModal,
    private router: Router,
    private activeModal: NgbActiveModal,
    private loader: NgxUiLoaderService,
    private datepipe: DatePipe,
    private service: CommonServices,
    public print: NgxPrintElementService
  ) {
    this.userdata = JSON.parse(
      localStorage.getItem(this.appname + 'userprofile')
    );
  }
  ngOnInit(): void {
    this.loader.start();
    setTimeout(() => {
      this.loader.stop();
    }, 2000);
    environment.production
      ? ''
      : console.log('print actual module', this.typename);

    if (this.type == 'batchprint') {
      if (this.assignto == undefined || this.assignto == null) {
        this.assignto = '';
      }
      this.printURL =
        environment.API_URL +
        'public/batchdownloadpdf?companyid=' +
        this.userdata.companyid +
        '&un=' +
        this.userdata.company_web_token +
        '&actionby=' +
        this.userdata.email +
        '&actiondate=' +
        this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') +
        '&logo=' +
        this.userdata.logo +
        '&vw=' +
        this.name +
        '&status=' +
        this.status +
        '&fromdate=' +
        this.fromdate +
        '&todate=' +
        this.todate +
        '&id=' +
        this.id +
        '&assignto=' +
        this.assignto;
      // const url = this.router.serializeUrl( this.printURL); //for andy's issue redirecting to new tab for print 04/03/2023
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.type == 'batchprinthtml') {
      this.printURL =
        environment.API_URL +
        'public/batchdownloadhtml?companyid=' +
        this.userdata.companyid +
        '&un=' +
        this.userdata.company_web_token +
        '&actionby=' +
        this.userdata.email +
        '&actiondate=' +
        this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') +
        '&logo=' +
        this.userdata.logo +
        '&vw=' +
        this.name +
        '&status=' +
        this.status +
        '&fromdate=' +
        this.fromdate +
        '&todate=' +
        this.todate +
        '&assignto=' +
        this.assignto;
      // const url = this.router.serializeUrl( this.printURL); //for andy's issue redirecting to new tab for print 04/03/2023
      window.open(this.printURL, '_blank');
      this.closeModal();
    } else if (this.name == 'printarstmt') {
      this.printURL =
        environment.API_URL +
        'public/downloadstmtpdf?id=' +
        this.id +
        '&type=' +
        this.type +
        '&fromdate=' +
        this.fromdate +
        '&todate=' +
        this.todate +
        '&companyid=' +
        this.userdata.companyid +
        '&un=' +
        this.userdata.company_web_token +
        '&actionby=' +
        this.userdata.email +
        '&actiondate=' +
        this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') +
        '&logo=' +
        this.userdata.logo +
        '&vw=' +
        this.name;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'printcd') {
      this.printURL =
        environment.API_URL +
        'public/printcd?id=' +
        this.id +
        '&companyid=' +
        this.userdata.companyid +
        '&un=' +
        this.userdata.company_web_token +
        '&actionby=' +
        this.userdata.email +
        '&actiondate=' +
        this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') +
        '&logo=' +
        this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'printvd') {
      this.printURL =
        environment.API_URL +
        'public/printvd?id=' +
        this.id +
        '&companyid=' +
        this.userdata.companyid +
        '&un=' +
        this.userdata.company_web_token +
        '&actionby=' +
        this.userdata.email +
        '&actiondate=' +
        this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') +
        '&logo=' +
        this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'apstatement') {
      this.printURL =
        environment.API_URL +
        'public/printapstmts?id=' +
        this.id +
        '&type=' +
        this.type +
        '&fromdate=' +
        this.fromdate +
        '&todate=' +
        this.todate +
        '&companyid=' +
        this.userdata.companyid +
        '&un=' +
        this.userdata.company_web_token +
        '&actionby=' +
        this.userdata.email +
        '&actiondate=' +
        this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') +
        '&logo=' +
        this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'emp_payslip') {
      this.printURL =
        environment.API_URL +
        'public/downloadpdf?id=' +
        this.id +
        '&companyid=' +
        this.userdata.companyid +
        '&un=' +
        this.userdata.company_web_token +
        '&actionby=' +
        this.userdata.email +
        '&actiondate=' +
        this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') +
        '&logo=' +
        this.userdata.logo +
        '&vw=payslip';
      // this.printURL = environment.API_URL + 'public/payslip?id=' + this.id + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'printbt') {
      //Batch print
      this.printURL =
        environment.API_URL +
        'public/printbt?id=' +
        this.id +
        '&companyid=' +
        this.userdata.companyid +
        '&un=' +
        this.userdata.company_web_token +
        '&actionby=' +
        this.userdata.email +
        '&actiondate=' +
        this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') +
        '&logo=' +
        this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'printp') {
      this.printURL =
        environment.API_URL +
        'public/printp?id=' +
        this.id +
        '&companyid=' +
        this.userdata.companyid +
        '&un=' +
        this.userdata.company_web_token +
        '&actionby=' +
        this.userdata.email +
        '&actiondate=' +
        this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') +
        '&logo=' +
        this.userdata.logo;
      // this.printURL = environment.API_URL + 'public/downloadpdf?id=' + this.id + '&companyid=' + this.userdata.companyid + '&vw=' + this.typename;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'viewseq') {
      this.printURL =
        environment.API_URL +
        'public/print4x2view?start=' +
        this.params.start +
        '&qty=' +
        this.params.qty +
        '&companyid=' +
        this.userdata.companyid +
        '&un=' +
        this.userdata.company_web_token +
        '&actionby=' +
        this.userdata.email +
        '&actiondate=' +
        this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') +
        '&logo=' +
        this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'printseq') {
      this.printURL =
        environment.API_URL +
        'public/print4x2sequence?start=' +
        this.params.start +
        '&qty=' +
        this.params.qty +
        '&printerid=' +
        this.params.printerid +
        '&companyid=' +
        this.userdata.companyid +
        '&un=' +
        this.userdata.company_web_token +
        '&actionby=' +
        this.userdata.email +
        '&actiondate=' +
        this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') +
        '&logo=' +
        this.userdata.logo;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'printso') {
      this.printURL =
        environment.API_URL +
        'public/printso?id=' +
        this.id +
        '&companyid=' +
        this.userdata.companyid +
        '&un=' +
        this.userdata.company_web_token +
        '&actionby=' +
        this.userdata.email +
        '&actiondate=' +
        this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') +
        '&logo=' +
        this.userdata.logo;
      // this.printURL = environment.API_URL + 'public/downloadpdf?id=' + this.id + '&companyid=' + this.userdata.companyid + '&vw=' + this.typename;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'printes') {
      this.printURL =
        environment.API_URL +
        'public/printes?companyid=' +
        this.userdata.companyid +
        '&un=' +
        this.userdata.company_web_token +
        '&actionby=' +
        this.userdata.email +
        '&actiondate=' +
        this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') +
        '&logo=' +
        this.userdata.logo +
        '&status=' +
        this.status +
        '&fromdate=' +
        this.fromdate +
        '&todate=' +
        this.todate;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'intership') {
      this.printURL =
        environment.API_URL +
        'public/intership?id=' +
        this.id +
        '&companyid=' +
        this.userdata.companyid +
        '&un=' +
        this.userdata.company_web_token +
        '&actionby=' +
        this.userdata.email +
        '&actiondate=' +
        this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') +
        '&logo=' +
        this.userdata.logo +
        '&empid=' +
        this.params.empid;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.typename == 'release') {
      this.printURL =
        environment.API_URL +
        'public/release?id=' +
        this.id +
        '&companyid=' +
        this.userdata.companyid +
        '&un=' +
        this.userdata.company_web_token +
        '&actionby=' +
        this.userdata.email +
        '&actiondate=' +
        this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') +
        '&logo=' +
        this.userdata.logo +
        '&empid=' +
        this.params.empid;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else if (this.name == 'printass') {
      this.printURL =
        environment.API_URL +
        'public/downloadasspdf?id=' +
        this.id +
        '&reviewer_type=' +
        this.reviewer_type +
        '&companyid=' +
        this.userdata.companyid +
        '&un=' +
        this.userdata.company_web_token +
        '&actionby=' +
        this.userdata.email +
        '&actiondate=' +
        this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') +
        '&logo=' +
        this.userdata.logo +
        '&vw=' +
        this.name;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    } else {
      this.printURL =
        environment.API_URL +
        'public/downloadpdf?id=' +
        this.id +
        '&companyid=' +
        this.userdata.companyid +
        '&un=' +
        this.userdata.company_web_token +
        '&actionby=' +
        this.userdata.email +
        '&actiondate=' +
        this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') +
        '&logo=' +
        this.userdata.logo +
        '&vw=' +
        this.name;
      // window.open(this.printURL, '_blank');
      // this.closeModal();
    }
     
this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
	this.loadComponentTranslations(lang); 
}); 
  }

  private loadComponentTranslations(lang: string) { 
    const translationFilePath = `./assets/i18n/servicemanager/servicecontracts/basicinfo/${lang}.json`; 
    this.http.get(translationFilePath).subscribe((translations:any) => { 
    this.translate.setTranslation(lang, translations, true); 
    this.translate.use(lang); 
    }); 
     } 
    
  closeModal() {
    this.activeModal.close('Modal Closed');
  }
}
