import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgToggleModule } from 'ng-toggle-button';
import { EmpadhocpaymentsComponent } from './empadhocpayments.component';

@NgModule({
    declarations: [EmpadhocpaymentsComponent],
    imports:[CommonModule, FormsModule, NgbModule,  NgbTooltipModule, NgToggleModule],
    exports: [EmpadhocpaymentsComponent],
})
export class EmpAdhocPaymentListModule {}