<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h5 class="modal-title f-w-600" id="exampleModalLabel">{{ 'title' | translate }}</h5>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
            </button>
            <button type="button" class="close" aria-label="Close" (click)="closeModal('close')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form class="needs-validation">
                <div class="form row">
                    <div class="form-group col-sm-6">
                        <label for="">{{ 'Form_Inputs2.Site_Name_Label' | translate }}</label>
                        <input type="text" class="form-control" name="name" [(ngModel)]="site.name">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">{{ 'Form_Inputs2.Site_Code_Label' | translate }}</label>
                        <input type="text" class="form-control" name="shortname" [(ngModel)]="site.shortname">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">{{ 'Form_Inputs2.Address1_Label' | translate }}</label>
                        <input type="text" class="form-control" name="address1" [(ngModel)]="site.address1">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">{{ 'Form_Inputs2.Address2_Label' | translate }}</label>
                        <input type="text" class="form-control" name="address2" [(ngModel)]="site.address2">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">{{ 'Form_Inputs2.Address3_Label' | translate }}</label>
                        <input type="text" class="form-control" name="address3" [(ngModel)]="site.address3">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">{{ 'Form_Inputs2.Address4_Label' | translate }}</label>
                        <input type="text" class="form-control" name="address4" [(ngModel)]="site.address4">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">{{ 'Form_Inputs2.Direction_Of_Site_Label' | translate }}</label>
                        <input type="text" class="form-control" name="directions" [(ngModel)]="site.directions">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">{{ 'Form_Inputs2.Point_Of_Contact_Label' | translate }}</label>
                        <input type="text" class="form-control" name="poc" [(ngModel)]="site.poc">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">{{ 'Form_Inputs2.Contact_Email_Label' | translate }}</label>
                        <input type="email" class="form-control" name="email" [(ngModel)]="site.email">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">{{ 'Form_Inputs2.Contact_Phone_Label' | translate }}</label>
                        <input type="number" class="form-control" name="phone" [(ngModel)]="site.phone">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">{{ 'Form_Inputs2.Notice_For_Outsiders_Label' | translate }}</label>
                        <input type="text" class="form-control" name="notice4outsiders"
                            [(ngModel)]="site.notice4outsiders">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">{{ 'Form_Inputs2.Latitude_Label' | translate }}</label>
                        <input type="text" class="form-control" name="lat" [(ngModel)]="site.lat">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">{{ 'Form_Inputs2.Longitude_Label' | translate }}</label>
                        <input type="text" class="form-control" name="lng" [(ngModel)]="site.lng">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">{{ 'Form_Inputs2.South_Label' | translate }}</label>
                        <input type="text" class="form-control" name="south" [(ngModel)]="site.south">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">{{ 'Form_Inputs2.West_Label' | translate }}</label>
                        <input type="text" class="form-control" name="west" [(ngModel)]="site.west">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">{{ 'Form_Inputs2.East_Label' | translate }}</label>
                        <input type="text" class="form-control" name="east" [(ngModel)]="site.east">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">{{ 'Form_Inputs2.North_Label' | translate }}</label>
                        <input type="text" class="form-control" name="north" [(ngModel)]="site.north">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">{{ 'Form_Inputs2.Is_Primary_Label' | translate }}</label>
                        <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                            <label class="d-block" for="isprimary">
                                <input class="radio_animated" [value]=true id="isprimary" type="radio" name="isprimary"
                                    [(ngModel)]="site.isprimary">
                                    {{ 'Form_Inputs2.Is_Primary_Radio_Yes' | translate }}
                            </label>
                            <label class="d-block" for="isprimary1">
                                <input class="radio_animated" [value]=false id="isprimary" type="radio" name="isprimary"
                                    [(ngModel)]="site.isprimary">
                                    {{ 'Form_Inputs2.Is_Primary_Radio_No' | translate }}
                            </label>
                        </div>
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">  {{ 'Form_Inputs2.Specmap_Label' | translate }}</label>
                        <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                            <label class="d-block" for="specmap">
                                <input class="radio_animated" [value]=true id="specmap" type="radio" name="specmap"
                                    [(ngModel)]="site.specmap">
                                    {{ 'Form_Inputs2.Specmap_Radio_Yes' | translate }}
                            </label>
                            <label class="d-block" for="specmap1">
                                <input class="radio_animated" [value]=false id="specmap" type="radio" name="specmap"
                                    [(ngModel)]="site.specmap">
                                    {{ 'Form_Inputs2.Specmap_Radio_No' | translate }}
                            </label>
                        </div>
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">{{ 'Form_Inputs2.Map_Picture_Label' | translate }}</label>
                        <input class="form-control" type="file" ng2FileSelect [uploader]="uploader"
                            (change)="selectedFileOnChanged($event)" accept="image/gif, image/jpeg, image/png" />
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                (click)="closeModal('close')">{{ 'Form_Buttons2.Form_Button2' | translate }}</button>
            <button type="button" class="btn btn-primary" (click)="addSites()">{{ 'Form_Buttons2.Form_Button3' | translate }}</button>
        </div>
    </div>
    <ngx-ui-loader></ngx-ui-loader>
</resize-border>