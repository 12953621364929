import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  FileSystemFileEntry,
  NgxFileDropEntry
} from 'ngx-file-drop';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/shared/service/language.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
// import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
})
export class ImportComponent implements OnInit {
  fullScreen = false;
  selectedCSVFileName: any;
  private langChangeSubscription: Subscription;
  isCSV_Valid: boolean;
  @Input('title') title;

  rulelist: any = [];
  csvContent: any;
  contacts: any[];
  properties: any[];
  flag: boolean;
  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    private http: HttpClient,

    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private toastr: NotificationService
  ) {
    environment.production ? '' : console.log('rule', this.rulelist);
    const csvData = '';
  }

  ngOnInit(): void {
    this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
      this.loadComponentTranslations(lang); 
    });     
  }

  private loadComponentTranslations(lang: string) { 
    const translationFilePath = `./assets/i18n/servicemanager/servicecontracts/import/${lang}.json`; 
    this.http.get(translationFilePath).subscribe((translations:any) => { 
    this.translate.setTranslation(lang, translations, true); 
    this.translate.use(lang); 
    }); 
     } 
    
    

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  public files: NgxFileDropEntry[] = [];

  public dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      // Is it a file?
      if (
        droppedFile.fileEntry.isFile &&
        this.isFileAllowed(droppedFile.fileEntry.name)
      ) {
        this.files = files;
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file
          environment.production
            ? ''
            : console.log(droppedFile.relativePath, file);
          const formData = new FormData();
          formData.append('File', file, droppedFile.relativePath);
        });
      } else {
        this.toastr.Error(
          "Only files in '.csv' format are accepted and directories are not allowed."
        );
        return false;
      }
    }
  }
  //  csvToArray(str, delimiter = ",") {
  //   // slice from start of text to the first \n index
  //   // use split to create an array from string by delimiter
  //   const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

  //   // slice from \n index + 1 to the end of the text
  //   // use split to create an array of each csv value row
  //   const rows = str.slice(str.indexOf("\n") + 1).split("\n");
  // }

  isFileAllowed(fileName: string) {
    let isFileAllowed = false;
    const allowedFiles = ['.csv'];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      }
    }
    return isFileAllowed;
  }
  //open csv file and read

  // fileChangeListener($event: any): void {

  //   const files = $event.srcElement.files;

  //   if (files !== null && files !== undefined && files.length > 0) {
  //     this.selectedCSVFileName = files[0].name;

  //     const reader: FileReader = new FileReader();
  //     reader.readAsText(files[0]);
  //     reader.onload = e => {

  //       const csv = reader.result;
  //       const results = this.papa.parse(csv as string, { header: false });
  //       // this.papa.parse(results.data[0], {
  //       //   complete: function (results) {
  //       //     environment.production ? '' : console.log('skfdsdfsd', results);
  //       //   }
  //       // });
  //       environment.production ? '' : console.log('csv file 11 ', results);

  //       // VALIDATE PARSED CSV FILE
  //       if (results !== null && results !== undefined && results.data !== null &&
  //         results.data !== undefined && results.data.length > 0 && results.errors.length === 0) {
  //         this.isCSV_Valid = true;

  //         // PERFORM OPERATIONS ON PARSED CSV
  //         let csvTableHeader = results.data[0];

  //         let csvTableData = [...results.data.slice(1, results.data.length)];
  //         environment.production ? '' : console.log('csv file ', csvTableData);

  //       } else {
  //         for (let i = 0; i < results.errors.length; i++) {
  //           environment.production ? '' : console.log('Error Parsing CSV File: ', results.errors[i].message);
  //         }
  //       }
  //     };
  //   } else {
  //     environment.production ? '' : console.log('No File Selected');
  //   }

  // }
  //

  onFileLoad(fileLoadedEvent) {
    const textFromFileLoaded = fileLoadedEvent.target.result;
    this.csvContent = textFromFileLoaded;
    environment.production ? '' : console.log('csv', this.csvContent);

    //Flag is for e xtracting first line
    let flag = false;
    // Main Data
    let objarray: Array<any> = [];
    //Properties
    let prop: Array<any> = [];
    //Total Length
    let size: any = 0;

    for (const line of this.csvContent.split(/[\r\n]+/)) {
      if (flag) {
        let obj = {};
        for (let k = 0; k < size; k++) {
          //Dynamic Object Properties
          obj[prop[k]] = line.split(',')[k];
        }
        objarray.push(obj);
      } else {
        //First Line of CSV will be having Properties
        for (let k = 0; k < line.split(',').length; k++) {
          size = line.split(',').length;
          //Removing all the spaces to make them usefull
          prop.push(line.split(',')[k].replace(/ /g, ''));
        }
        flag = true;
      }
    }
    this.contacts = objarray;
    this.properties = [];

    this.properties = prop;
    environment.production ? '' : console.log(this.properties);
    environment.production ? '' : console.log(this.contacts);
    this.flag = true;
    // environment.production ? '' : console.log(this.csvContent);
  }

  onFileSelect(input: HTMLInputElement) {
    const files = input.files;
    var fileTypes = ['csv']; //acceptable file types

    if (files && files.length) {
      var extension = input.files[0].name.split('.').pop().toLowerCase(), //file extension from input file
        isSuccess = fileTypes.indexOf(extension) > -1; //is extension in acceptable types
      //environment.production ? '' : console.log(isSuccess);
      //  environment.production ? '' : console.log("Filename: " + files[0].name);
      // environment.production ? '' : console.log("Type: " + files[0].type);
      //  environment.production ? '' : console.log("Size: " + files[0].size + " bytes");
      if (isSuccess) {
        const fileToRead = files[0];

        const fileReader = new FileReader();
        fileReader.onload = this.onFileLoad;

        fileReader.readAsText(fileToRead, 'UTF-8');
      } else {
        // this.toastr.error("Invalid File Type", 'Failed');
      }
    }
  }
}
