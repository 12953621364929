import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription, interval } from 'rxjs';
import { SalesServices } from 'src/app/components/Sales/sales.service';
import { CommonServices } from 'src/app/shared/service/common.service';
import { LanguageService } from 'src/app/shared/service/language.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customerlistmodal',
  templateUrl: './customerlist.component.html',
  styleUrls: ['./customerlist.component.scss']
})
export class CustomerslistComponent implements OnInit {
  @ViewChild('custname') custname;
  pos: any = {};
  userdata: any;
  appname = environment.APP_NAME;
  setCustomerValue: any;
   
private langChangeSubscription: Subscription;
  keyword = 'name';
  validatecustomer: any;
  customerList: any = []
  customer: any = {
    searchtxt: '',
    siteid: null,
  }
  subscription: Subscription;
  @ViewChild('SearchElement') SearchElement: ElementRef;
  selectedCustomer: any = {};
  siteList: any = [];
  constructor(private translate: TranslateService, 
    private languageService: LanguageService, 
    private http: HttpClient,private activeModal: NgbActiveModal,
    // private maincomponent:AddContractsComponent,
    private commonServices: CommonServices,
    private loader: NgxUiLoaderService,
    private datePipe: DatePipe,
    private toastr: NotificationService,
    private service: SalesServices,
  ) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.getCompanySites();
  }

  selectSite() {
    if (this.customer.siteid) {
      this.getCustomers();
    } else {
      this.customerList = [];
    }
  }
  getCustomers() {
    if(!this.customer.siteid){
      if (this.customer.searchtxt == '') {
        return;
      }
      if (this.customer.searchtxt.length < 3) {
        return;
      }
    }
    this.loader.start();
    this.commonServices.getCustomers(this.customer.searchtxt, this.customer.siteid).subscribe(res => {
      this.loader.stop();
      this.customerList = res;
      environment.production ? '' : console.log(' customer list data ', this.customerList);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading customer list ', err);
    });
  }
  getCompanySites() {
    this.loader.start();
    this.commonServices.getCompanySites().subscribe(res => {
      this.loader.stop();
      this.siteList = res;
      environment.production ? '' : console.log(' customer list data ', this.customerList);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading customer list ', err);
    });
  }
  selectCustomer(item) {
    this.selectedCustomer = item;
    this.customerList = [];
  }
  addCustomer() {
    if (this.customer.searchtxt == null || this.customer.searchtxt == undefined || this.customer.searchtxt == '') {
      this.toastr.Error('Please enter customer name');
      return;
    }
    if (this.customer.primaryphone == null || this.customer.primaryphone == undefined || this.customer.primaryphone == '') {
      this.toastr.Error('Please enter phone number');
      return;
    }
    var obj = {
      id: null,
      name: this.customer.searchtxt,
      mainemail: this.customer.email,
      companyid: this.userdata.companyid,
      primaryphone: this.customer.primaryphone,
      createby: this.userdata.email,
      status: this.customer.status,
      createdate: this.datePipe.transform(new Date(), this.userdata.postdatetimeformat),
      updateby: null,
      updatedate: null
    }
    this.loader.start();
    this.service.postcustomer(obj).subscribe(res => {
      this.loader.stop();
      // this.customerList = res;
      if (res != null) {
        this.customer.searchtxt = '';
        this.customer.email = '';
        this.customer.primaryphone = '';
        this.getCustomers();
      }
      environment.production ? '' : console.log('post customer response ', this.customerList);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading post customer ', err);
    });
  }

  closeModal() {
    this.activeModal.close(this.selectedCustomer);
  }
  ngOnInit(): void {
    this.customer.searchtxt = this.custname ? this.custname : '';
    this.getCustomers();
    
this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
	this.loadComponentTranslations(lang); 
}); 
  }
  private loadComponentTranslations(lang: string) { 
    const translationFilePath = `./assets/i18n/servicemanager/servicecontracts/customerlistmodal/${lang}.json`; 
    this.http.get(translationFilePath).subscribe((translations:any) => { 
    this.translate.setTranslation(lang, translations, true); 
    this.translate.use(lang); 
    }); 
     } 
    
    
  ngAfterViewInit() {
    const source = interval(25000);
    this.SearchElement.nativeElement.focus()
    this.subscription = source.subscribe(val => {
      if (!this.SearchElement.nativeElement.focus()) {
        this.SearchElement.nativeElement.focus()

      }

    });
  }

  ngOnDestroy() {
    this.subscription ? this.subscription.unsubscribe() : null;
    console.log('destroyed')
  }

}
