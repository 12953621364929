import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomerslistComponent } from './customerlist.component';

@NgModule({
    declarations: [CustomerslistComponent],
    imports:[HttpClientModule, 
        TranslateModule.forRoot({ 
         loader: { 
         provide: TranslateLoader, 
         useFactory: HttpLoaderFactory, 
         deps: [HttpClient] 
         } 
         }) 
        ,CommonModule, FormsModule, NgbModule, NgbTooltipModule, NgSelectModule],
    exports: [CustomerslistComponent],
})
export class CustomerListModule {}
export function HttpLoaderFactory(http: HttpClient) { 
    return new TranslateHttpLoader(http); 
   } 
    