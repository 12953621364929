<div class="padding-div">
    <form (keydown.enter)="getReferences()">
        <div class="form-row">
            <div class="col-lg-12 col-md-12 form-group position-relative">
                <i class="fa fa-search hover-icon"></i><input #SearchElement style="padding-left:20px;" type="text"
                    class="form-control" name="searchtxt" placeholder="Search by Supplier Name" [(ngModel)]="searchtxt"
                    (click)="preventEvent($event)" (input)="getReferences()">
            </div>
        </div>
    </form>
    <div class="category-table custom-datatable mt-1">
        <div class="table-responsive">
            <table class="custom-table">
                <thead>
                    <tr class="custom-tr">
                        <th class="custom-th text-center">Award#</th>
                        <th class="custom-th text-center">Name</th>
                        <th class="custom-th text-center">POC</th>
                        <th class="custom-th text-center">Payment Terms</th>
                        <th class="custom-th text-center">Currency</th>
                        <th class="custom-th text-center">Local</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="custom-tr" *ngFor="let item of paymentList">
                        <td class="custom-td position-relative text-left">
                            <div class="hyperlink" (click)="selectRef(item)" ngbDropdownItem>{{item.refno}}
                            </div>
                        </td>
                        <td class="custom-td position-relative text-left">
                            <div class="hyperlink" (click)="selectRef(item)" ngbDropdownItem>{{item.emp_name}}
                            </div>
                        </td>
                        <td class="custom-td position-relative text-left">
                            <div class="hyperlink" (click)="selectRef(item)" ngbDropdownItem>{{item.description}}
                            </div>
                        </td>
                        <td class="custom-td position-relative text-left">
                            <div class="hyperlink" (click)="selectRef(item)" ngbDropdownItem>{{item.currency}}
                            </div>
                        </td>
                        <td class="custom-td position-relative text-right">
                            <div class="hyperlink" (click)="selectRef(item)" ngbDropdownItem>{{item.roe}}
                            </div>
                        </td>
                        <td class="custom-td position-relative text-right">
                            <div class="hyperlink" (click)="selectRef(item)" ngbDropdownItem>{{item.amount}}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="no-results" *ngIf="paymentList.length == 0">Your search result yielded no results</div>
        </div>
    </div>
</div>