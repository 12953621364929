import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonServices } from 'src/app/shared/service/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-purchaseinvoicepayment',
  templateUrl: './purchaseinvoicepayment.component.html',
  styleUrls: ['./purchaseinvoicepayment.component.scss']
})
export class PurchaseinvoicepaymentComponent implements OnInit {
  fullScreen = false;
  @Input('id') id;
  @Input('totalcost') totalcost;
  @Input('dueamount') dueamount;

  userdata: any;
  appname = environment.APP_NAME;
  paymentMethodList: any = [];
  payment: any = {}
  companyBanks: any = [];
  constructor(private activeModal: NgbActiveModal,
    private commonService: CommonServices,) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.commonService.getPaymentMethods().subscribe(res => {
      this.paymentMethodList = res;
      environment.production ? '' : console.log('paymentMethodList list', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading paymentMethodList list ', err);
    });
  }
  getPaymentAccounts() {
    var type = '';
    this.paymentMethodList.forEach(item => {
      if (item.Value == this.payment.mop) {
        type = item.Value1 ? item.Value1 : '';
      }
    })
    this.commonService.getCompMOPBankList(this.payment.mop).subscribe(res => {
      this.companyBanks = res;
    }, err => {
    });
  }
  ngOnInit(): void {
    this.payment.amt = this.totalcost;
    this.payment.balance = this.dueamount;
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }



}
