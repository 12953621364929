import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { element } from 'screenfull';
import { InventoryServices } from 'src/app/components/inventory/inventory.service';
import { CommonServices } from 'src/app/shared/service/common.service';
import { LanguageService } from 'src/app/shared/service/language.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
@Component({
	selector: 'app-serviceitem',
	templateUrl: './serviceitem.component.html',
	styleUrls: ['./serviceitem.component.scss']
})
export class ServiceitemComponent implements OnInit {
	private langChangeSubscription: Subscription;
	product: any = {
		ptype: 'service',
		dept: '',
		cat: '',
		rhservice: 0,
		ohservice: 0,
		ahservice: 0,
		flatprice: 0,
		flatpp: false,
		status: 'active',
		sale_online: false,
		parentid: null,
		pocost: 0,
		avgcost: 0,
		rhservice_agg: 0,
		ahservice_agg: 0,
		ohservice_agg: 0
	};
	isedit: boolean = true;
	userdata: any;
	appname = environment.APP_NAME;
	@Input('id') id;
	@Input('action') action;
	categoryList: any;
	accounts: any;
	deptList: any;
	glAccountList: any = [];
	glCostofSalesSettings: any = {};
	glIncomeAccountSettings: any = {};
	coa_costofsales: any;
	coa_income: any;
	statusList: any;
	lastaction: any;
	accountGroupingList: any;
	@ViewChild('glaccount') glaccount;
	uploader: FileUploader;
	filename: any;
	msg: any;
	parentProds: any = [];
	constructor(

		private translate: TranslateService,
		private languageService: LanguageService,
		private http: HttpClient,

		private cdRef: ChangeDetectorRef,
		private commonService: CommonServices, private route: ActivatedRoute, private toastr: NotificationService, private activeModal: NgbActiveModal, private loader: NgxUiLoaderService, private datepipe: DatePipe, private invservice: InventoryServices, private ModalService: NgbModal) {
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
		// this.action = this.route.snapshot.queryParamMap.get('action');
		this.initializeFileUploader();
		this.getProducts();
	}

	closeModal() {
		this.activeModal.close('Modal Closed');
	}
	getProducts() {
		this.invservice.getParaentProducts('', 'service').subscribe(res => {
			this.parentProds = res;
			this.parentProds.forEach(item => {
				item.Value = item.Value ? Number(item.Value) : '';
			})
		})
	}
	ngOnInit(): void {
		if (this.id) {
			this.getitemById();
			if (this.action == 'view') {
				this.isedit = false;
			}
		}
		this.getDeptData();
		this.commonService.getCOAList('leaf').subscribe(res => {
			this.accounts = res;
			// this.dropdownList.forEach(element => {
			// 	element.Value = Number(element.Value)
			// })
			this.glAccountList = [];
			this.accounts.forEach(element => {
				this.glAccountList.push({
					"id": Number(element.Value),
					"itemName": element.Text,
					"category": element.Value1,
				})
			});
			this.accountGroupingList = Array.from(new Set(this.accounts.map(({ Value1 }) => Value1)));
			this.setGLAccounts();
		})
		this.glIncomeAccountSettings = {
			disabled: false,
			itemDisabled: false,
			singleSelection: true,
			text: "Select GL Income",
			groupBy: 'category',
			enableSearchFilter: true,
			searchPlaceholderText: 'Search GL Income'
		};

		this.glCostofSalesSettings = {
			disabled: false,
			itemDisabled: false,
			singleSelection: true,
			text: "Select GL Cost Sales",
			groupBy: 'category',
			enableSearchFilter: true,
			searchPlaceholderText: 'Search GL Cost Sales'
		};
		this.commonService.getComboEnums('prodstatus').subscribe(res => {
			this.statusList = res;
		}, err => {
		});



		this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => {
			this.loadComponentTranslations(lang);
		});
	}


	private loadComponentTranslations(lang: string) {
		const translationFilePath = `./assets/i18n/servicemanager/servicelineitems/${lang}.json`;
		this.http.get(translationFilePath).subscribe((translations: any) => {
			this.translate.setTranslation(lang, translations, true);
			this.translate.use(lang);
		});
	}


	setGLAccounts() {
		if (this.accounts.length == 0) {
			return;
		}
		this.accounts.forEach(element => {
			if (this.product.id) {
				if (element.Value == this.product.coa_income) {
					this.glIncomeAccountSettings = {
						disabled: false,
						itemDisabled: false,
						singleSelection: true,
						text: element.Text,
						groupBy: element.Value1,
						enableSearchFilter: true,
						searchPlaceholderText: ''
					}
				}
				if (element.Value == this.product.coa_costofsales) {
					this.glCostofSalesSettings = {
						disabled: false,
						itemDisabled: false,
						singleSelection: true,
						text: element.Text,
						groupBy: element.Value1,
						enableSearchFilter: true,
						searchPlaceholderText: ''
					}
				}
			}
		});
	}

	getitemById() {
		this.loader.start();
		this.invservice.getInventoryItemsDetials(this.id).subscribe(res => {
			this.loader.stop();
			this.product = res;
			if (this.product.pic !== null) {
				this.filename = this.product.pic;
				this.product.pic = environment.DOC_URL + '/' + this.userdata.companyid + '/' + this.product.pic
			}
			this.getCatData();
			this.setGLAccounts();
			environment.production ? '' : console.log('item details ', res);
		}, err => {
			this.loader.stop();
		})
	}

	getDeptData() {
		this.commonService.getDepartmentList(true).subscribe(res => {
			this.deptList = res;
		}, err => {
		});
	}

	getCatData() {
		this.loader.start();
		this.commonService.getCategory(Number(this.product.dept), true).subscribe(res => {
			this.categoryList = res;
			this.loader.stop();
		}, err => {
			this.loader.stop();
		});
	}

	onItemSelect(item: any) {
		environment.production ? '' : console.log(item);
		this.product.coa_income = item.id;
		environment.production ? '' : console.log(this.product.coa_income);
	}

	OnItemDeSelect(item: any) {
		environment.production ? '' : console.log(item);
		this.product.coa_income = null;
		environment.production ? '' : console.log(this.product.coa_income);
	}


	onCosSelect(item: any) {
		environment.production ? '' : console.log(item);
		this.product.coa_costofsales = item.id;
		environment.production ? '' : console.log(this.product.coa_costofsales);
	}

	OnCosDeSelect(item: any) {
		environment.production ? '' : console.log(item);
		this.product.coa_costofsales = null;
		environment.production ? '' : console.log(this.product.coa_costofsales);
	}


	saveItem() {
		if (this.product.cat) {
			this.product.cat = parseFloat(this.product.cat);
		}
		if (this.product.dept) {
			this.product.dept = parseFloat(this.product.dept);
		}
		if (this.product.coa_income) {
			this.product.coa_income = parseFloat(this.product.coa_income);
		}
		if (this.product.id) {
			this.product.updateby = this.userdata.email;
			this.product.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
		} else {
			this.product.id = null;
			this.product.companyid = this.userdata.companyid;
			this.product.createby = this.userdata.email;
			this.product.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
		}
		this.product.pic = this.filename ? this.filename : this.product.pic;
		this.loader.start();
		this.invservice.postProduct(this.product).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				if (res.status_code == "1") {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
				} else {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
					return;
				}
				this.ModalService.dismissAll();
			}
			environment.production ? '' : console.log('post product response ', res);
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading post product ', err);
		});
	}
	validateIlc() {
		this.loader.start();
		this.commonService.validateIlc(this.product.ilc).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				if (res.status_code == "1") {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
				} else {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
					if (this.action == 'view') {
						this.product.ilc = '';
					}
					return;
				}
			}
			environment.production ? '' : console.log('discountTypeList   ', res);
		}, err => {
			environment.production ? '' : console.log('Error : While loading discountTypeList data', err);
		});
	}
	initializeFileUploader() {
		this.uploader = new FileUploader({
			authTokenHeader: 'authorization',
			authToken: 'Bearer ' + this.userdata.token,
			url: environment.API_URL + 'api/Documents/UploadProductPictures',
			method: 'POST',
			removeAfterUpload: true,
			queueLimit: 100
		})
		this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
			form.append('companyid', this.userdata.companyid);
			form.append('createby', this.userdata.email);
			form.append('dtype', 'item-files');
		};
		// this.response = '';
		this.uploader.response.subscribe(res => {
			environment.production ? '' : console.log('res ' + res);
			// this.response = res
		});
		this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
	}


	selectedFileOnChanged(event: any) {
		environment.production ? '' : console.log(event.target.value);
		// var filename = event.target.value.Replace('C:\'fakepath\','')
		this.filename = event.target.value.split(/(\\|\/)/g).pop()
		this.product.pic = this.filename;
		if (!event.target.files[0] || event.target.files[0].length == 0) {
			this.msg = 'You must select an image';
			return;
		}
		var mimeType = event.target.files[0].type;
		if (mimeType.match(/image\/*/) == null) {
			this.msg = "Only images are supported";
			return;
		}
		var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		reader.onload = (_event) => {
			this.msg = "";
			this.product.pic = reader.result;
		}
		this.uploadFile();
	}
	uploadFile() {
		this.uploader.queue[0].onSuccess = function (response, status, headers) {
			if (status == 200) {
				let tempRes = JSON.parse(response);
			} else {
				// this.toaster.Success('File uploaded');
				this.initializeFileUploader();
			}
		};
		this.uploader.queue[0].upload();
	}

}
