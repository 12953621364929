import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppServices } from 'src/app/shared/service/app.services';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { SalesServices } from '../../Sales/sales.service';
import { CreditComponent } from '../credit/credit.component';
import { GenericprintComponent } from '../genericprint/genericprint.component';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})

export class PaymentComponent implements OnInit {
  fullScreen = false;
  @ViewChild('content') Content: ElementRef;
  @Input('invoiceid') invoiceid = null;
  @Input('customerid') customerid = null;
  appname = environment.APP_NAME;
  paidTotal: any = 0;
  total: any = 0;
  allCheck: any = false;
  list: any;
  overDueBal: any = 0;
  customerPaymentsList: any = []
  payment: any = {
    leave_credit: false,
    notify_customer: false,
    mop: '',
    payment_type: 'normal',
    amt: 0,
    availbalance: 0,
    bankid: '',
    tendered_amt: 0
  }
  userdata: any;
  paymentMethodList: any = [];
  paymentTypeList: any = []
  dueInv: any = {
    availbalance: 0
  };
  dueTotal: any = 0;
  paidCurrTotal: any = 0;
  baseTotal: any = 0;
  duepayments: any = []
  dueList: any = []
  customer: any = {
    name: '',
    id: null
  }
  dueBaseTotal: any = 0;
  decimal: any = '1.2-2';
  isNumberShow = false;
  selectedMOP: any;
  currencyList: any = [];
  companyBanks: any = [];
  receivedBaseAmount: any = 0;
  fxLossGain: any = 0;
  constructor(private modalService: NgbModal,
    private serviceSales: SalesServices,
    private appService: AppServices,
    private toaster: NotificationService,
    private activeModal: NgbActiveModal,
    private datePipe: DatePipe,
    private commonService: CommonServices, private datepipe: DatePipe, private cd: ChangeDetectorRef, private loader: NgxUiLoaderService, private service: SalesServices) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.payment.trandate = this.datepipe.transform(new Date(), this.userdata.postdateformat);
    if (this.userdata.decimals_allowed) {
      this.decimal = '1.' + this.userdata.decimals_allowed + '-' + this.userdata.decimals_allowed;
    }
    this.commonService.getPaymentMethods().subscribe(res => {
      this.paymentMethodList = res;
      environment.production ? '' : console.log('payment method data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading payment method data', err);
    });
    this.appService.getCurrency().subscribe(res => {
      this.currencyList = res;
      environment.production ? '' : console.log(' currency list data ', this.currencyList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading Currency list ', err);
    });
    // this.commonService.getPaymentTypes().subscribe(res => {
    //   this.paymentTypeList = res;
    //   environment.production ? '' : console.log('payment types data ', res);
    // }, err => {
    //   environment.production ? '' : console.log('Error : While loading payment types data', err);
    // });
    this.commonService.getComboEnums('paymenttype').subscribe(res => {
      this.paymentTypeList = res;
      environment.production ? '' : console.log('payment types data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading payment types data', err);
    });
  }
  ngOnInit(): void {
    this.getInvoiceList(this.customerid, this.invoiceid);
    this.getCustomerDetails();
  }

  getPaymentAccounts() {
    this.commonService.getCompMOPBankList(this.payment.mop).subscribe(res => {
      this.companyBanks = res;
    }, err => {
    });
  }
  openCreditModal() {
    var modalRef = this.modalService.open(CreditComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.customerid = this.customerid;
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  getCustomerDetails() {
    this.loader.start();
    this.serviceSales.getCustomerDetails(this.customerid).subscribe(res => {
      this.customer = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
    })
  }
  getInvoiceList(customerid, invid) {
    this.service.getReceivePaymentList(customerid, '', '', invid ? invid : '', 'customer').subscribe(res => {
      this.dueInv = res;
      if (this.dueInv.currency) {
        this.selectROE()
      }
      this.dueTotal = 0;
      this.duepayments = this.dueInv.duepayments;
      this.payment.availbalance = this.dueInv.availbalance ? Number(this.dueInv.availbalance).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.dueInv.availbalance = this.dueInv.availbalance ? Number(this.dueInv.availbalance).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.duepayments.forEach(element => {
        element.invdate = this.datePipe.transform(element.invdate, this.userdata.postdateformat);
        this.dueTotal += element.bal;
        element.bal = element.bal ? Number(element.bal).toFixed(this.userdata.decimals_allowed) : 0.00;
        element.paidamt = Number(0).toFixed(this.userdata.decimals_allowed);
        element.checked = false;
        if (element.ttype == 1) {
          element.type = 'Sales Invoice';
        } else if (element.ttype == 2) {
          element.type = 'Sales Order';
        }
        element.duebasetotal = (element.roe ? Number(element.roe) : 1) * (element.bal ? Number(element.bal) : 0);
        this.dueBaseTotal += Number(element.duebasetotal);
        element.duebasetotal = element.duebasetotal ? Number(element.duebasetotal).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);

        element.basetotal = Number(0).toFixed(this.userdata.decimals_allowed);
        element.paidbasecurrtotal = Number(0).toFixed(this.userdata.decimals_allowed);
      });
      this.dueBaseTotal = this.dueBaseTotal ? Number(this.dueBaseTotal).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.baseTotal = Number(0).toFixed(this.userdata.decimals_allowed);
      this.paidCurrTotal = Number(0).toFixed(this.userdata.decimals_allowed);
      this.dueTotal = this.dueTotal ? Number(this.dueTotal).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.paidTotal = Number(0).toFixed(this.userdata.decimals_allowed);
      environment.production ? '' : console.log('due list ', this.duepayments);

    }, err => {
      environment.production ? '' : console.log('Error : While loading payment method data', err);
    });
  }
  print(item) {
    var printtype;
    if (item.ttype == 1) {
      printtype = 'printi';
    } else if (item.ttype == 2) {
      printtype = 'printso';
    }
    var modalRef = this.modalService.open(GenericprintComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' })
    modalRef.componentInstance.id = item.refid;
    modalRef.componentInstance.name = printtype;
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
    }).catch((Error) => {
      environment.production ? '' : console.log(Error);
    });
  }
  selectPaymentType() {
    this.isNumberShow = false;
    this.paymentMethodList.forEach(element => {
      if (element.Value == this.payment.mop) {
        this.selectedMOP = element;
        if (element.Value1 == 'cheque') {
          this.isNumberShow = true;
        }
      }
    });
    this.makeChequeFieldsNull();
    this.getPaymentAccounts();
  }
  makeChequeFieldsNull() {
    this.payment.number = '';
    this.payment.cheque_details = '';
    this.payment.cheque_date = null;
  }
  change(item) {
    if (item.paidamt == undefined || item.paidamt == null) {
      item.paidamt = 0;
    }
    if (item.paidamt > 0) {
      item.checked = true;
      this.allCheck = true;
    } else {
      item.checked = false;
      this.allCheck = false;
    }
    if (Number(item.bal) < Number(item.paidamt)) {
      item.paidamt = item.bal;
    }
    this.calculateLineItemTotal();
  }
  selectAll(event) {
    if (event.target.checked == true) {
      this.allCheck = true;
      this.duepayments.forEach(element => {
        element.checked = true;
      });
    } else {
      this.allCheck = false;
      this.duepayments.forEach(element => {
        element.checked = false;
      });
    }
    this.calculateLineItemTotal();
  }
  updatePayAmt() {
    if (this.payment.tendered_amt == undefined || this.payment.tendered_amt == null || this.payment.tendered_amt == '') {
      this.payment.tendered_amt = Number(0).toFixed(this.userdata.decimals_allowed);
    }
    if (Number(this.payment.tendered_amt) > Number(this.paidTotal)) {
      this.payment.change = Number(this.payment.tendered_amt) - Number(this.paidTotal);
    } else {
      this.payment.change = 0;
    }
    this.payment.change = Number(this.payment.change).toFixed(this.userdata.decimals_allowed);

    this.fxLossGain = (this.baseTotal ? Number(this.baseTotal) : 0) - (this.paidCurrTotal ? Number(this.paidCurrTotal) : 0);
    this.fxLossGain = this.fxLossGain ? Number(this.fxLossGain).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);

    this.receivedBaseAmount = (((this.payment.tendered_amt ? Number(this.payment.tendered_amt) : 0) - (this.payment.change ? Number(this.payment.change) : 0)) * Number(this.payment.roe ? this.payment.roe : 0));
    this.receivedBaseAmount = this.receivedBaseAmount ? Number(this.receivedBaseAmount).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);


  }
  calculateLineItemTotal() {
    this.paidTotal = 0;
    this.dueTotal = 0;
    this.baseTotal = 0;
    this.fxLossGain = 0;
    this.receivedBaseAmount = 0;
    this.payment.change = Number(0).toFixed(this.userdata.decimals_allowed);
    this.allCheck = true;
    this.duepayments.forEach(element => {
      if (element.checked) {
        element.paidamt = (element.paidamt > 0) ? Number(element.paidamt).toFixed(this.userdata.decimals_allowed) : Number(element.bal).toFixed(this.userdata.decimals_allowed);
        this.paidTotal += Number(element.paidamt);
      } else {
        this.allCheck = false;
        element.paidamt = Number(0).toFixed(this.userdata.decimals_allowed);
      }
      element.basetotal = (element.roe ? Number(element.roe) : 1) * (element.paidamt ? Number(element.paidamt) : 0);
      this.baseTotal += Number(element.basetotal);
      element.basetotal = element.basetotal ? Number(element.basetotal).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      this.dueTotal += element.bal ? Number(element.bal) : 0;
      element.bal = Number(element.bal).toFixed(this.userdata.decimals_allowed);
      element.paidbasecurrtotal = (element.paidamt ? element.paidamt : 0) * (this.payment.roe ? this.payment.roe : 1);
      element.paidbasecurrtotal = element.paidbasecurrtotal ? Number(element.paidbasecurrtotal).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    });
    this.baseTotal = this.baseTotal ? Number(this.baseTotal).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.payment.tendered_amt = Number(this.paidTotal).toFixed(this.userdata.decimals_allowed);
    this.paidTotal = Number(this.paidTotal).toFixed(this.userdata.decimals_allowed);
    this.dueTotal = Number(this.dueTotal).toFixed(this.userdata.decimals_allowed);
    this.paidCurrTotal = (this.paidTotal ? Number(this.paidTotal) : 0) * (this.payment.roe ? this.payment.roe : 1);
    this.paidCurrTotal = this.paidCurrTotal ? Number(this.paidCurrTotal).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);

    this.fxLossGain = (this.baseTotal ? Number(this.baseTotal) : 0) - (this.paidCurrTotal ? Number(this.paidCurrTotal) : 0);
    this.fxLossGain = this.fxLossGain ? Number(this.fxLossGain).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);

    this.receivedBaseAmount = (((this.payment.tendered_amt ? Number(this.payment.tendered_amt) : 0) - (this.payment.change ? Number(this.payment.change) : 0)) * Number(this.payment.roe ? this.payment.roe : 0));
    this.receivedBaseAmount = this.receivedBaseAmount ? Number(this.receivedBaseAmount).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);

  }
  trackByIndex(index: number, obj: any): any {
    return index;
  }
  addPayment() {
    if (this.selectedMOP.Value1 == 'cheque' && (this.payment.number == null || this.payment.number == '')) {
      this.toaster.Error("Please enter cheque#");
      return;
    }
    if (this.selectedMOP.Value1 == 'online' && (this.payment.bankid == '' || this.payment.bankid == null)) {
      this.toaster.Error("Please select bank");
      return;
    }
    if (this.payment.tendered_amt == undefined || this.payment.tendered_amt == null || this.payment.tendered_amt == 0) {
      this.toaster.Error('Please enter amount')
      return;
    }
    if (this.payment.memo == undefined || this.payment.memo == null || this.payment.memo == '') {
      this.toaster.Error("Please enter memo");
      return;
    }
    if (!this.dueInv.currency) {
      this.toaster.Error('Please select currency ')
      return;
    }
    if (this.payment.tendered_amt < this.paidTotal) {
      this.toaster.Error("Please check payment amount, it can not be less than invoice payment grand total.");
      return;
    }

    this.dueList = [];
    this.duepayments.forEach(element => {
      if (element.checked) {
        this.dueList.push({
          "customer_name": element.customer_name,
          "status": element.status,
          "refno": element.refno,
          "projref": element.projref,
          "currency": element.currency,
          "bal": element.bal ? Number(element.bal) : 0,
          "pay": element.paidamt ? Number(element.paidamt) : 0,
          "refid": element.refid,
          "ttype": element.ttype,
          "chekd": element.checked,
          "invdate": element.invdate
        })
      }
    });
    var obj = {
      "notes": this.payment.notes,
      "memo": this.payment.memo,
      "change": this.payment.change ? Number(this.payment.change) : 0,
      "amt": this.paidTotal ? Number(this.paidTotal) : 0,
      "availbalance": this.payment.availbalance ? Number(this.payment.availbalance) : 0,
      "currency": this.dueInv.currency,
      "partyid": this.customerid,
      "party_name": this.customer.name,
      "party_type": 'customer',
      "companyid": this.userdata.companyid,
      "notify_customer": this.payment.notify_customer,
      "mop": Number(this.payment.mop),
      "leave_credit": this.payment.leave_credit,
      "trandate": this.datepipe.transform(this.payment.trandate, this.userdata.postdateformat),
      "createby": this.userdata.email,
      "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "payment_type": this.payment.payment_type,
      "companyname": this.userdata.compayname,
      "phone": this.userdata.phone,
      "duepayments": this.dueList,
      "roe": this.payment.roe ? Number(this.payment.roe) : 0,
      "bankid": this.payment.bankid ? this.payment.bankid : null,
      "cheque_details": this.payment.cheque_details ? this.payment.cheque_details : '',
      "cheque_date": this.payment.cheque_date ? this.datepipe.transform(new Date(this.payment.cheque_date), this.userdata.date_format) : null,
      "tendered_amt": this.payment.tendered_amt ? this.payment.tendered_amt : 0,
      "fx_loss_gain": (this.baseTotal ? Number(this.baseTotal) : 0) - (this.paidCurrTotal ? Number(this.paidCurrTotal) : 0)
    }
    environment.production ? '' : console.log('payment data', obj);
    // return false
    this.loader.start();
    this.service.postPayment(obj).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toaster.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message) : this.toaster.Error(res.message);
          return;
        }
        this.paidTotal = 0;
        this.dueTotal = 0;
        this.dueBaseTotal = 0;
        this.baseTotal = 0;
        this.paidCurrTotal = 0;
        this.fxLossGain = 0;
        this.receivedBaseAmount = 0;
        this.payment = {
          trandate: this.datepipe.transform(new Date(), this.userdata.postdateformat),
          leave_credit: false,
          notify_customer: false,
          mop: '',
          payment_type: 'normal'
        }
        this.duepayments = [];
        this.getInvoiceList(this.customerid, this.invoiceid);
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading device ', err);
    });
  }
  selectROE() {
    this.currencyList.forEach(element => {
      if (this.dueInv.currency == element.Value) {
        this.payment.roe = Number(element.Value2).toFixed(this.userdata.decimals_allowed) ? Number(element.Value2).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      }
      else if (!this.dueInv.currency) {
        this.payment.roe = '';
      }
    })
  }
  getCurrentROE() {
    if (!this.dueInv.currency) {
      this.toaster.Error('Please select currency');
      return;
    }
    this.loader.start();
    this.commonService.getExchangeRate(this.userdata.currency, this.dueInv.currency).subscribe(res => {
      this.loader.stop();
      this.dueInv.roe = res ? Number(res).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    }, err => {
      this.loader.stop();
    });
  }

}
