<div class="padding-div">
   <form (keydown.enter)="getCustomers()">
    <div class="form-row">
        <div class="col-lg-12 col-md-12 form-group d-flex ">
            <ng-select placeholder="Select Site" name="siteid" [clearable]="false" [(ngModel)]="customer.siteid" (change)="selectSite()">
                <ng-option value="">{{ 'SelectSite' | translate }}</ng-option>
                <ng-option *ngFor="let item of siteList" [value]="item.Value">{{item.Text}}</ng-option>
            </ng-select>
            <div class="position-relative">
                <i class="fa fa-search hover-icon"></i><input #SearchElement style="padding-left:20px;" type="text" class="form-control" name="customername" placeholder="{{ 'Customer' | translate }}" [(ngModel)]="customer.searchtxt" (input)="getCustomers()" autocomplete="off">
            </div>
        </div>
    </div>
</form>
<div class="category-table custom-datatable mt-1">
    <div class="table-responsive">
        <table class="custom-table">
                <tr class="custom-tr">
                    <th class="custom-th text-center">{{ 'Form_Table.Code_Column1' | translate }}</th>
                    <th class="custom-th text-center">{{ 'Form_Table.Name_Column2' | translate }}</th>
                    <th class="custom-th text-center">{{ 'Form_Table.StartName_Column3' | translate }}</th>
                    <th class="custom-th text-center">{{ 'Form_Table.TP_Column4' | translate }}</th>
                    <th class="custom-th text-center">{{ 'Form_Table.Auth_Column5' | translate }}</th>
                    <th class="custom-th text-center">{{ 'Form_Table.Phone_Column6' | translate }}</th>
                    <th class="custom-th text-center">{{ 'Form_Table.Email_Column7' | translate }}</th>
                </tr>

                <tr class="custom-tr" *ngFor="let item of customerList">
                    <td class="custom-td position-relative text-center"><div class="hyperlink"
                            (click)="selectCustomer(item)" ngbDropdownItem>{{item.accountno}}</div>
                        </td>
                    <td class="custom-td position-relative text-left" ><div class="hyperlink"
                            (click)="selectCustomer(item)" ngbDropdownItem [ngbTooltip]="Information" placement="bottom top" tooltipClass="modal-dropdown-tooltip">{{item.name}}</div>
                            <ng-template #Information>
                                <div class="d-flex flex-column">
                                  <div><b>Name:</b> {{item.name}} 
                                    </div>
                                  <div><b>ShortName:</b> {{item.shortname}}
                                    </div>
                                  <div><b>TPAcc#:</b> {{item.tpaccno}}</div>
                                  <div><b>Auth Required?:</b> <span *ngIf="item.authorizationreq">Yes</span><span
                                  *ngIf="!item.authorizationreq">No</span></div>
                                  <div><b>Phone:</b> {{item.primaryphone}}</div>
                                  <div><b>Email:</b> {{item.mainemail}}</div>
                                </div>
                              </ng-template>
                        </td>
                    <td class="custom-td position-relative text-left">
                        <span class="hyperlink" (click)="selectCustomer(item)" ngbDropdownItem>{{item.shortname}}</span>
                    </td>
                    <td class="custom-td position-relative text-left"><span class="hyperlink"
                            (click)="selectCustomer(item)" ngbDropdownItem>{{item.tpaccno}}</span></td>
                    <td class="custom-td position-relative text-center"><span *ngIf="item.authorizationreq">Yes</span><span
                            *ngIf="!item.authorizationreq">No</span></td>
                    <td class="custom-td position-relative text-left">{{item.primaryphone}}</td>
                    <td class="custom-td position-relative text-left">{{item.mainemail}}</td>
                </tr>

        </table>
        <div class="no-results" *ngIf="customerList.length == 0">Your search result yielded no results</div>
    </div>
    
</div>
</div>