<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h5 class="modal-title f-w-600" id="exampleModalLabel">Cheque Tracking# {{billPayment.cheque_no}}</h5>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
            </button>
            <button type="button" class="close" aria-label="Close" (click)="closeModal('close')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form class="needs-validation">
                <div class="form row">
                    <div class="form-group col-sm-6">
                        <label for="">Clear Date</label>
                        <mat-form-field>
                            <input matInput [matDatepicker]="picker421" placeholder="Choose a date"
                                [(ngModel)]="tracking.cleardate" name="cleardate">
                            <mat-datepicker-toggle matSuffix [for]="picker421"></mat-datepicker-toggle>
                            <mat-datepicker #picker421></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">Status</label>
                        <select class="form-control" name="status" [(ngModel)]="tracking.status">
                            <option value="">Select Status</option>
                            <option *ngFor="let item of statusList" [value]="item.stringValue">{{item.Text}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">Notify Party </label>
                        <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                            <label class="d-block" for="">
                                <input class="radio_animated" [value]="true" id="" type="radio" name="rdo-ani"
                                    [(ngModel)]="tracking.partynotified">
                                Yes
                            </label>
                            <label class="d-block" for="">
                                <input class="radio_animated" [value]="false" id="" type="radio" name="rdo-ani"
                                    [(ngModel)]="tracking.partynotified">
                                No
                            </label>
                        </div>
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">Reason</label>
                        <textarea type="text" class="form-control" rows="3" name="reason"
                            [(ngModel)]="tracking.reason"></textarea>
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="">Notes</label>
                        <textarea type="text" class="form-control" name="notes" rows="3"
                            [(ngModel)]="tracking.notes"></textarea>
                    </div>
                    <div class="form-group col-sm-6">
                        <label>Documents</label>
                        <input class="form-control" name="attchemnt" type="file" ng2FileSelect [uploader]="uploader"
                            (click)="cancelAttachment()" (change)="selectedFileOnChanged($event)" multiple
                            accept="application/pdf, image/gif, image/jpeg, image/png" />
                        <span *ngIf="t_bill_payment_files.length>0">{{t_bill_payment_files.length}} file
                            attached</span>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                (click)="closeModal('close')">Close</button>
            <button type="button" class="btn btn-primary" (click)="saveTracking()">Save</button>
        </div>

        <div class="modal-body">
            <div class="form row">
                <div class="table-responsive2">
                    <table class="table  input-table">
                        <thead>
                            <tr>
                                <th class="text-center">ID</th>
                                <th class="text-center">Notes</th>
                                <th class="text-center">Reason</th>
                                <th class="text-center">Party Notified</th>
                                <th class="text-center">Files</th>
                                <th class="text-center">Status</th>
                                <th class="text-center">Created By</th>
                                <th class="text-center">Create Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of trackingLog">
                                <td class="text-left">{{item.id}}</td>
                                <td class="text-left">{{item.notes}}</td>
                                <td class="text-left">{{item.reason}}</td>
                                <td class="text-center"><span *ngIf="item.partynotified">Yes</span><span
                                        *ngIf="!item.partynotified">No</span></td>
                                <td class="text-left hyperlink"><span *ngFor="let file of item.files"
                                        style="display: block;"><a target="_blank"
                                            [href]="file.url">{{file.file_url}}</a></span>
                                </td>
                                <td class="text-center">{{item.status}}</td>
                                <td class="text-left">{{item.createby}}</td>
                                <td class="text-left">{{item.createdate | date:userdata.postdatetimeformat}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</resize-border>