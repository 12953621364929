<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h5 class="modal-title f-w-600" id="exampleModalLabel">Announcements</h5>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
            </button>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="category-table custom-datatable">
                <div class="table-responsive">
                    <table class="custom-table">
                        <tr class="custom-tr">
                            <th class="custom-th text-center" (click)="onSortClick($event)">
                                Title <i class="Sorting fa fa-chevron-up"></i>
                            </th>
                            <th class="custom-th text-center" (click)="onSortClick($event)">
                                Description <i class="Sorting fa fa-chevron-up"></i>
                            </th>
                            <th class="custom-th text-center" (click)="onSortClick($event)">
                                Start Date <i class="Sorting fa fa-chevron-up"></i>
                            </th>
                            <th class="custom-th text-center" (click)="onSortClick($event)">
                                End Date <i class="Sorting fa fa-chevron-up"></i>
                            </th>
                        </tr>
                        <ng-container>
                            <tr class="custom-tr" *ngFor="let item of announcements;">
                                <td class="custom-td text-left">
                                    {{item.title}}
                                </td>
                                <td class="custom-td text-left">
                                    {{item.description}}
                                </td>
                                <td class="custom-td text-center text-nowrap">
                                    {{item.startdate | date:userdata.date_format}}
                                </td>
                                <td class="custom-td text-center text-nowrap">
                                    {{item.enddate | date:userdata.date_format}}
                                </td>
                            </tr>
                        </ng-container>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                (click)="closeModal()">Close</button>
        </div>
    </div>
</resize-border>