import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { CommonServices } from 'src/app/shared/service/common.service';
import { LanguageService } from 'src/app/shared/service/language.service';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {
  fullScreen = false;
  companyLogo: any = 'http://ezybooks.net/docs/logos/';
  format: any;
  userdata: any;
  appname = environment.APP_NAME;
  device: any = {
    name: '',
    ilc: '',
    type: '',
  }
   
private langChangeSubscription: Subscription; 

  deviceList: any = [];
  selectedDevice: any;
  constructor(
    private translate: TranslateService, 
private languageService: LanguageService, 
private http: HttpClient, private activeModal: NgbActiveModal,
    private loader: NgxUiLoaderService,
    private commonService: CommonServices,
    private datepipe: DatePipe,) {
    //#region userdata block
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.format = this.userdata.date_format
    this.companyLogo += this.userdata.logo;
    MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
    //#endregion userdata block
    this.getDevice();
  }
  getDevice() {
    this.loader.start();
    this.commonService.getDevice(this.device.name, this.device.ilc, this.device.type,).subscribe(res => {
      this.loader.stop();
      this.deviceList = res;
      environment.production ? '' : console.log('device list data ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading device list data', err);
    });
  }

  ngOnInit(): void {
     
this.langChangeSubscription = this.languageService.getCurrentLanguage().subscribe((lang) => { 
	this.loadComponentTranslations(lang); 
}); 
  }


   
private loadComponentTranslations(lang: string) { 
  const translationFilePath = `./assets/i18n/servicemanager/equipements&devices/devicemodal/${lang}.json`; 
  this.http.get(translationFilePath).subscribe((translations:any) => { 
  this.translate.setTranslation(lang, translations, true); 
  this.translate.use(lang); 
  }); 
   } 
  selectDevice(item) {
    this.selectedDevice = item;
    this.closeModal();
  }
  closeModal() {
    this.activeModal.close(this.selectedDevice);
  }

}
