import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader } from 'ng2-file-upload';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-chequetracking',
  templateUrl: './chequetracking.component.html',
  styleUrls: ['./chequetracking.component.scss']
})
export class ChequetrackingComponent implements OnInit {
  fullScreen = false;
  tracking: any = {};
  @Input('params') params;
  billPayment: any;
  userdata: any;
  appname: any = environment.APP_NAME;
  issuedStatus: any = [];
  t_bill_payment_files: any = [];
  trackingLog: any = [];
  uploader: FileUploader;
  response: any;
  filename: any;
  statusList: any = [];
  constructor(private activeModal: NgbActiveModal, private toastr: NotificationService, private loader: NgxUiLoaderService, private service: CommonServices, private datepipe: DatePipe) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    service.getComboEnums('BillPaychequestatus').subscribe(res => {
      this.statusList = res;
    })
    this.initialize();
  }
  initialize() {
    this.tracking = {
      "id": null,
      "status": "",
      "notes": "",
      "createdate": "",
      "createby": "",
      "bpid": null,
      "reason": "",
      "partynotified": false,
      "t_bill_payment_files": []
    }
  }
  ngOnInit(): void {
    this.billPayment = this.params;
    this.getTrackingLog();
  }
  closeModal(action) {
    this.activeModal.close(action);
  }
  initializeFileUploader() {
    this.uploader = new FileUploader({
      authTokenHeader: 'authorization',
      authToken: 'Bearer ' + this.userdata.token,
      url: environment.API_URL + 'api/Documents/UploadFiles',
      method: 'POST',
      removeAfterUpload: true,
      queueLimit: 100
    })
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('companyid', this.userdata.companyid);
      form.append('refno', this.billPayment.number);
      form.append('createby', this.userdata.email);
      form.append('createdate', this.datepipe.transform(new Date(), this.userdata.postdatetimeformat));
      form.append('dtype', 'cheque-tracking-files');
    };
    this.response = '';
    this.uploader.response.subscribe(res => {
      environment.production ? '' : console.log('res ' + res);
      this.response = res.data;
    });
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onAfterAddingAll = (files: any) => {
      files.forEach(fileItem => {
        environment.production ? '' : console.log('file name: ' + fileItem.file.name);
        this.t_bill_payment_files.push({
          "id": null,
          "bpid": this.billPayment.bpid,
          "rptid": this.billPayment.rptid ? this.billPayment.rptid : '',
          "file_url": fileItem.file.name,
          "active": true,
          "createby": this.userdata.email,
          "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
        })
      });
    };
  }
  cancelAttachment() {
    this.t_bill_payment_files = [];
  }
  selectedFileOnChanged(event: any) {
    environment.production ? '' : console.log(event.target.value);
    this.filename = event.target.value.split(/(\\|\/)/g).pop();
  }
  uploadFile() {
    this.t_bill_payment_files.forEach((file, index) => {
      if (this.t_bill_payment_files.length - 1 == index) {
        this.t_bill_payment_files = [];
        this.getTrackingLog();
      }
      this.uploader.queue[index].onSuccess = function (response, status, headers) {
        if (status == 200) {
          let tempRes = JSON.parse(response);
          environment.production ? '' : console.log('upload', status);
        } else {
          // this.toastr.Success("File uploaded falid")
          environment.production ? '' : console.log('upload faild', status);
          // Swal.fire('', 'File uploaded', 'success');
        }
      };
      this.uploader.queue[index].upload();
    })
  }
  getTrackingLog() {
    this.initialize();
    this.loader.start();
    this.service.getIssuedChequeTracking(this.billPayment.bpid).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        this.trackingLog = res;
        this.trackingLog.forEach(item => {
          item.files.forEach(element => {
            element.url = environment.DOC_URL + '/' + element.file_url;
          })
        })
      }
    }, err => {
      this.loader.stop();
    })
  }
  saveTracking() {
    if (!this.tracking.cleardate) {
      this.toastr.Error('Please select cheque clear date');
      return;
    }
    if (!this.tracking.status) {
      this.toastr.Error('Please select status');
      return;
    }
    this.tracking.bpid = this.billPayment.bpid;
    this.tracking.t_bill_payment_files = this.t_bill_payment_files;
    this.tracking.companyid = this.userdata.companyid;
    this.tracking.cleardate = this.datepipe.transform(new Date(this.tracking.cleardate), this.userdata.postdatetimeformat);
    this.tracking.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    this.tracking.updateby = this.userdata.email;
    this.loader.start();
    this.service.postIssuedTracking(this.tracking).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        if (this.t_bill_payment_files.length > 0) {
          this.uploadFile();
        } else {
          this.getTrackingLog();
        }
      }
    }, err => {
      this.loader.stop();
    })
  }

}
